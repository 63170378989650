import React from 'react';
import AddressDeliveryMapper from "../../shared/mappers/addressDeliveryMapper";

const AddressDeliveryOrderInfoView = ({delivery}) => {
    const deliveryView = AddressDeliveryMapper.map(delivery);
    if (!deliveryView) {
        return null;
    }
    return (
        <div className={"user-address-views"}>
            <h5 className={'text-dark'}>{deliveryView?.name}</h5>
            <ul className={"m-0 p-0"} style={{listStyle: "none"}}>
                {deliveryView.form.map(address => {
                    return (<li className={"text-muted d-flex justify-content-between"}><strong
                        className={""}>{address.label} :</strong>
                        <span>{address.value}</span></li>)
                })}
            </ul>
        </div>
    );
};

export default AddressDeliveryOrderInfoView;