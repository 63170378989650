export const ORDERS_DELIVERIES_SET_LIST = "ORDERS_DELIVERIES/SET_LIST";
export const ORDER_DELIVERY_VIEW_INFO_MODAL = "ORDER_DELIVERY/VIEW_INFO_MODAL";

export const setOrderDeliveriesAction = (deliveries = null) => {
    return {
        type: ORDERS_DELIVERIES_SET_LIST,
        payload: deliveries
    }
}
export const setOrderDeliveryViewInforModalAction = ({
                                             isOpen = false,
                                             delivery = null
                                         }) => {
    return {
        type: ORDER_DELIVERY_VIEW_INFO_MODAL,
        payload: {isOpen: isOpen, delivery: delivery},
    }
}