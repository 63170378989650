import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDeliveryViewInfoSelector} from "../../../store/selectors/order-deliveries-selector";
import Modal from "../MyModal/Modal";
import {setOrderDeliveryViewInforModalAction} from "../../../store/actions/orderDeliveriesAction";
import AddressDeliveryOrderInfoView from "../../AddressDelivery/AddressDeliveryOrderInfoView";

const DeliveryOrderViewInfoModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(getDeliveryViewInfoSelector);

    if (!modal?.isOpen) return null;
    const onClose = () => {
        dispatch(setOrderDeliveryViewInforModalAction({isOpen: false, delivery: null}));
    }

    return (
        <Modal title={`${getT("Информация о доставке")}`} open={modal.isOpen}
               onClose={onClose} size="sm">
            {/*<p>{getT("Заказ")} </p>*/}
            <AddressDeliveryOrderInfoView delivery={modal.delivery?.delivery}/>
        </Modal>
    );
};

export default DeliveryOrderViewInfoModal;