import {ORDER_DELIVERY_VIEW_INFO_MODAL, ORDERS_DELIVERIES_SET_LIST} from "../actions/orderDeliveriesAction";

const initialState = {
    ordersDeliveriesList: null, //object or null
    deliveryViewInfoModal:{
        isOpen: false,
        delivery: null //object or null
    }
}


const orderDeliveriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_DELIVERIES_SET_LIST: {
            return {
                ...state,
                ordersDeliveriesList: action.payload
            }
        }
        case ORDER_DELIVERY_VIEW_INFO_MODAL:{
            return {
                ...state,
                deliveryViewInfoModal: {
                    ...state.deliveryViewInfoModal,
                    ...action.payload
                }
            }
        }
        default: {
            return state;
        }
    }
}
export default orderDeliveriesReducer