import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {getOrdersXncStatusByGroupThunk} from "../../store/thunks/orders/order-xnc-status-thunk";
import {caseOrdersDeliveriesListThunk} from "../../store/thunks/orders/case-orders-deliveries-list-thunk";

const OrderXncStatusProvider = ({orders, children}) => {
   const dispatch = useDispatch();
    useEffect(() => {
        const ordersAll =Object.values(orders).flat().filter(order => !!order)
       if(!Array.isArray(ordersAll) || !ordersAll.length) return null
        const ordersIds =ordersAll
            .map(item=> item.id)

        dispatch(getOrdersXncStatusByGroupThunk(ordersIds));
        dispatch(caseOrdersDeliveriesListThunk(ordersIds));
    }, [orders]);
    return (
        <>
            {children}
        </>
    );
};

export default OrderXncStatusProvider;