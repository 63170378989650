import {ORDER_XNC_PROBLEM__MODAL, ORDERS_XNC_STATUS_SET_GROUP} from "../actions/OrdersXncStatusActions";

const INITIAL_STATE = {
    ordersXncStatusGroup: null,
    orderXncProblemModal: {
        isOpen: false,
        order: null,
    }
}

const OrdersXncStatusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORDERS_XNC_STATUS_SET_GROUP: {
            return {
                ...state,
                ordersXncStatusGroup: action.payload
            }
        }
        case ORDER_XNC_PROBLEM__MODAL: {
            const {isOpen, order} = action.payload;
            return {
                ...state,
                orderXncProblemModal: {
                    isOpen: isOpen,
                    order: order
                }
            }
        }
        default: {
            return state;
        }
    }
}
export default OrdersXncStatusReducer;