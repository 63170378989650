import React, {useCallback, useEffect, useState} from "react";
import PageTitle from "../PageTitle/PageTitle.jsx";
import {Link} from "react-router-dom";
import SetProblemContainer from "../Modals/SetProblem/SetProblemContainer";
import ProgrammMakeContainer from "../Modals/ProgrammMake/ProgrammMakeContainer";
import "../Orders/ResponsiveTable.css";
import {v4 as uuid} from "uuid";
import Modal from "../Modals/MyModal/Modal";
import {ServicesModal} from "./ServicesModal";
import {MultiSelect} from "react-multi-select-component";
import {
    changeContragentDataThunc,
    createProductionTaskThunk, orderOnUpdateServicesAndUpdateStatus,
    orders_in,
    OrdersComplete,
    OrdersFor,
    OrdersForShipping,
    OrdersIn,
    updateArrayOrderDataThunk,
    updateOrderProductionDateThunk,
    updateOrderProductionStageThunk
} from "../../store/reducers/MainReducer";

import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "../Orders/OrderOutstanding";
import {withLang} from "../../hoc/withLang";
import {gaEvents} from "../../gaEvents";
import InputChecked from "./ui/InputChecked";
import GroupTransfer from "./ui/GroupTransfer";
import "./css/main.css"
import SettingsCookies from "./ui/SettingsCookies";
import useProductionDisplayManualMode from "../../hook/useProductionDisplayManualMode";
import THeader from "./ui/table/THeader";
import {transformTimeAndDate} from "../../printHtml/helpers";
import {getSettingProductionByKey} from "../../store/selectors/main_selector";
import OrderXncAction from "./ui/OrderXncAction";
import {getOrdersXncStatusByGroup} from "../../store/selectors/orders-xnc-status-selector";
import {getOrdersDeliveriesListSelector} from "../../store/selectors/order-deliveries-selector";
import OrderItemDeliverySlot from "../AddressDelivery/OrderItemDeliverySlot";

const ProductionTask = (props) => {
    const {ids_orders, onToggleDisplayAll, onToggleIdOrders, isManualMode} = useProductionDisplayManualMode()
    const orderStatus = useSelector(state => state.main.orderStatus);
    const ordersXncStatusGroup = useSelector(getOrdersXncStatusByGroup);
    const getOrdersDeliveriesList = useSelector(getOrdersDeliveriesListSelector);
    // const q_salary_usage_wihth_report_button = 1;
    const q_salary_usage_wihth_report_button = useSelector(getSettingProductionByKey('production.q_salary_usage_wihth_report_button')) || 0
    const findByName = useCallback((name) => {

        if (Array.isArray(orderStatus)) {
            return orderStatus.find(status => props.getT(status.name.toUpperCase()) === name.toUpperCase())
        }
        return null
    }, [orderStatus, props.getT]);


    const lang = useSelector(state => state.translation.local);
    useEffect(() => {
    }, []);
    const localization = {
        allItemsAreSelected: props.getT("Выбраны все"),
        selectSomeItems: props.getT("Выбрать"),
        search: props.getT("Поиск"),
        selectAll: props.getT("Выбрать все")
    };

    let checkedOrders = props.ordersFor
        ? props.ordersFor.filter((el) => el.isChecked === true)
        : [];

    const ok = props.ordersFor
        ? props.ordersFor.filter(
            (el) =>
                el.client_DB.client_DB_permit === 1 &&
                el.goods_order_without_part === 0
        )
        : [];

    const bad = props.ordersFor
        ? props.ordersFor?.filter(
            (el) =>
                el.client_DB.client_DB_permit === 0 &&
                el.goods_order_without_part === 0
        )
        : [];

    const badCount = bad ? bad.length : 0;
    const okCount = ok ? ok.length : 0;
    // const okCount = props.ordersFor?.length;
    const materialsFilter = ok.map((el) => el.mat);
    const materialFilterArr = flatDeep(materialsFilter, Infinity);
    const serviceFilter = ok.map((el) => el.serv);
    const servFilterArr = flatDeep(serviceFilter, Infinity);

    const uniqueServFilters = servFilterArr.filter(
        (
            (set) => (f) =>
                !set.has(f?.name) && set.add(f?.name)
        )(new Set())
    );
    const uniqueMatFilters = materialFilterArr.filter(
        (
            (set) => (f) =>
                !set.has(f?.name) && set.add(f?.name)
        )(new Set())
    );

    const myfunk = () => {
        const materialOptions = uniqueMatFilters
            .sort((a, b) => (a.name > b.name && 1) || -1)
            .map((el) => {
                return {
                    label: el.name,
                    value: el.name
                };
            });
        return materialOptions;
    };

    const myfunk1 = () => {
        const serviceOptions = uniqueServFilters.map((el) => {
            return {
                label: el?.name,
                value: el?.name
            };
        });
        return serviceOptions;
    };
    useEffect(() => {
        setSelectedMat(myfunk());
        setSelectedServ(myfunk1());
    }, [props]);
    const [selectedMat, setSelectedMat] = useState(myfunk());
    const [selectedServ, setSelectedServ] = useState(myfunk1());

    const options = [
        {label: props.getT("Не кромковать"), value: "NO"},
        {label: props.getT("ЭВА кромкование"), value: "EVA"},
        {label: props.getT("PUR кромкование"), value: "PUR"},
        {label: props.getT("Лазерное кромкование"), value: "LAZ"}
    ];

    const glueOptions = [
        {label: props.getT("Прозрачный"), value: "transparent"},
        {label: props.getT("Белый"), value: "white"},
        {label: props.getT("Черный"), value: "black"}
    ];
    let testOBJ = [
        {
            orders_glue_type_id: "265",
            order: "19513",
            goods: "21853",
            type: "1",
            type_info: "transparent"
        },
        {
            orders_glue_type_id: "266",
            order: "19513",
            goods: "14799",
            type: "1",
            type_info: "transparent"
        }
    ];

    const [selected, setSelected] = useState(options);
    const [selectedGlue, setSelectedGlue] = useState(glueOptions);

    const selectedMatrialsValues = selectedMat.map((item) => item.value);
    const selectedServicesValues = selectedServ.map((item) => item.value);
    const selectedGlueValue = selectedGlue.map((item) => item.value);

    const [que, setQue] = useState(-1);
    const handleSetQue = (e) => {
        setQue(e.target.value);
    };
    const [prod, setProd] = useState(0);
    const handleSetProd = (e) => {
        setProd(e.target.value);
    };
    let query = selected.map((el) => {
        return el.value;
    });

    const dispatch = useDispatch();
    const status = useSelector((state) => state.auth.user.role);
    const roles = status.map((el) => el.name);

    const canEdit = !roles.includes("CEO") && roles.includes("manager");


    // *********************Производственные Заказы **************************//
    const actionTypes = [
        {action: "create", value: props.getT("Создать новое производственное задание")},
        {action: "add-orders", value: props.getT("Добавить к выбранному")},
        // {
        //     action: "send-orders-production",
        //     value: props.getT("Отправить выделенные в производство без формирования задания")
        // },
    ];

    const [action, setAction] = useState("");
    const [prodName, setProdName] = useState("");
    const [task, setTask] = useState("");
    const [prodTaskOrders, setProdTaskOrders] = useState(null);
    const prodOrders = {
        action: action,
        orders: prodTaskOrders,
        name: prodName,
        task: task,
        manager: localStorage.getItem("i_furn_user_id")
    };
    const sendOrderProductionHandler = () => {
        dispatch(updateArrayOrderDataThunk(prodOrders.orders, {status: 4}, 'actual', () => {
            setShowFiltersModalChekedOrders(false);
        }));

    }
    const checkProductionTaskDisabled = (production_task) => {
        if (!production_task) return "";
        if (!production_task.status) return "";
        if (production_task.status && Number(production_task.status) <= 3)
            return "";
        return "disabled";
    };

    const prodOrdersReq = () => {
        closeModal();
        dispatch(createProductionTaskThunk(prodOrders));
    };
    // *********************Производственные Заказы **************************//
    const [searchID, setSearchID] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [searchName, setSearchName] = useState("");
    const [searchClient, setSearchClient] = useState("");
    const [searchManager, setSearchManager] = useState("");
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [showFiltersModalChekedOrders, setShowFiltersModalChekedOrders] =
        useState(false);

    const closeModal = () => setShowFiltersModalChekedOrders(false);
    const [showFiltersModalFor, setShowFiltersModalFor] = useState(false);
    const [showFiltersModalBad, setShowFiltersModalBad] = useState(false);
    const [showFiltersModalOverdue, setShowFiltersModalOverdue] = useState(false);
    const [activeOrder, setActiveOrder] = useState();
    const [activeOrderInId, setActiveOrderInId] = useState();

    const [servicesFilter, setServicesFilter] = useState();
    const [materialFilter, setMaterialFilter] = useState("");
    const [bandFilter, setBandFilter] = useState("");
    const [servFilter, setServFilter] = useState("");
    const [servType, setServType] = useState("");

    const [servicesFilterOverdue, setServicesFilterOverdue] = useState();
    const [materialFilterOverdue, setMaterialFilterOverdue] = useState("");
    const [bandFilterOverdue, setBandFilterOverdue] = useState("");
    const [servFilterOverdue, setServFilterOverdue] = useState("");
    const [servTypeOverdue, setServTypeOverdue] = useState("");

    const [servicesFilterCheck, setServicesFilterCheck] = useState();

    const [servicesFilterFor, setServicesFilterFor] = useState();
    const [materialFilterFor, setMaterialFilterFor] = useState("");
    const [bandFilterFor, setBandFilterFor] = useState("");
    const [servFilterFor, setServFilterFor] = useState("");
    const [servTypeFor, setServTypeFor] = useState("");

    const [servicesFilterBad, setServicesFilterBad] = useState();
    const [materialFilterBad, setMaterialFilterBad] = useState("");
    const [bandFilterBad, setBandFilterBad] = useState("");
    const [servFilterBad, setServFilterBad] = useState("");
    const [servTypeBad, setServTypeBad] = useState("");

    const [openChangeDateProduction, changeDateProductionOpen] = useState(null);
    const changeProductionDateOrder = (order_id, date) =>
        dispatch(updateOrderProductionDateThunk(order_id, date));
    const changeProductionStageOrder = (order_id, stage) =>
        dispatch(updateOrderProductionStageThunk(order_id, stage));

    const [openChangeOrderStageProduction, changeOrderStageProduction] =
        useState(null);
    const [visibleClearDate, setVisibleClearDate] = useState(false);

    const [openDatePicker, setOpenDatePicker] = useState(false);

    const toggleOpenDatePicker = () => {
        setOpenDatePicker(!openDatePicker);
        setDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
    };

    const [date, setDate] = useState([
        {
            startDate: new Date(null),
            endDate: new Date(null),
            key: "selection"
        }
    ]);
    const [date_production, setDate_production] = useState([
        {
            startDate: new Date(null),
            endDate: new Date(null),
            key: "selection"
        }
    ]);

    const saveData = () => {
        setDate([
            {
                from: formatDate(date[0].startDate),
                to: formatDate(date[0].endDate)
            }
        ]);
        setOpenDatePicker(false);
        setVisibleClearDate(true);
    };

    const clearData = () => {
        setDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
        setVisibleClearDate(false);
    };

    const [visibleClearDate_production, setVisibleClearDate_production] =
        useState(false);

    const [openDate_productionPicker, setOpenDate_productionPicker] =
        useState(false);

    const toggleOpenDate_productionPicker = () => {
        setOpenDate_productionPicker(!openDate_productionPicker);
        setDate_production([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
    };

    const saveData_production = () => {
        setDate_production([
            {
                from: formatDate(date_production[0].startDate),
                to: formatDate(date_production[0].endDate)
            }
        ]);
        setOpenDate_productionPicker(false);
        setVisibleClearDate_production(true);
    };

    const clearData_production = () => {
        setDate_production([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
        setVisibleClearDate_production(false);
    };
    // ******************************

    const allServiceChek = new Set();

    const allServTypeChek = new Set();
    let filteredDataCkek;
    if (checkedOrders.length > 0) {
        filteredDataCkek = checkedOrders.filter((el) => {
            // el.calc.material.type === "serv";

            const shouldBeShown = el;

            if (shouldBeShown) {
                el.calc.material.map((item) => {
                    let name = item.hasOwnProperty('name_translate') ? item['name_translate'][lang] : item.name;
                    if (item.type === "serv" && Number(item.ready_serv) !== 1) {
                        switch (item.type) {
                            case "serv":
                                return (
                                    allServiceChek.add(name) &&
                                    allServTypeChek.add(item[`type_serv_name_${lang}`])
                                );
                            default:
                                return false;
                        }
                    }
                });

                return true;
            }

            return false;
        });
    } else {
        filteredDataCkek = [];
    }

    let selectedServicesCountCheck = 0;

    if (servicesFilterCheck) {
        // считаем кол-во сервисов и фильтруем таблицу по ним
        allServiceChek = allServiceChek.filter((item) => {
            const service = item.calc.service.find(
                (service) => service.name === servicesFilterCheck
            );

            if (service) {
                selectedServicesCountCheck += service.count;
                return true;
            }

            return false;
        });
    }

    // let allServiceCheckArr = Array.from(allServiceChek);

    // let allServTypeCheckArr = Array.from(allServTypeChek);

    const sortedDataCheck = filteredDataCkek.map((elem) => {
        return elem.calc.material;
    });

    function flatDeep(arr, d = 1) {
        return d > 0
            ? arr.reduce(
                (acc, val) =>
                    acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
                []
            )
            : arr.slice();
    }

    const convertInArrayCheck = flatDeep(sortedDataCheck, Infinity);

    let sortedByCut = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Порезка"))
    );

    let sortedbyStraight_edge = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Кромкование прямолинейное"))
    );

    let sortedbyDriling = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Сверление"))
    );

    let sortedbyStitching = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Сшивка"))
    );

    let sortedbyMilling = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Фрезерование"))
    );
    let sortedbyPaz = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Пазование"))
    );

    let sortedbyСurvilinear = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Кромкование криволинейное"))
    );

    let sortedbyTruncation = convertInArrayCheck.filter((el) =>
        el[`type_serv_name_${lang}`]?.includes(props.getT("Урезка"))
    );

    const sortedByCutSum = sortedByCut
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyStraight_edgeSum = sortedbyStraight_edge
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyDrilingSum = sortedbyDriling
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyStitchingSum = sortedbyStitching
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyMillingSum = sortedbyMilling
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyPazSum = sortedbyPaz
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyСurvilinearSum = sortedbyСurvilinear
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedbyTruncationSum = sortedbyTruncation
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    // *************************Просроченные заказы сумма********************//
    const allServiceOverdue = new Set();
    const allMaterialOverdue = new Set();
    const allBandOverdue = new Set();
    const allServTypeOverdue = new Set();

    let filteredDataOverdue;

    if (props.ordersIn.length > 0) {
        filteredDataOverdue = props.ordersIn.filter((el) => {
            let query_manager = !searchManager ? true : Number(el?.manager?.id) === Number(searchManager);
            const shouldBeShown =
                el.id.includes(searchID) &&
                el.code.includes(searchCode) &&
                el.order_out_of_date === 1 &&
                el.name.includes(searchName) &&
                el.client.name.toLowerCase().includes(searchClient) &&
                query_manager &&
                (!date[0].from || el.confirmed_date >= date[0].from) &&
                (!date[0].to || el.confirmed_date <= date[0].to) &&
                (!date_production[0].from ||
                    el.plan_of_production >= date_production[0].from) &&
                (!date_production[0].to ||
                    el.plan_of_production <= date_production[0].to);

            if (shouldBeShown) {
                el.calc?.material?.map((item) => {
                    // item.filter((e) => Number(e.ready_serv) !== 1);\
                    // if (Number(item.ready_serv) === 1) {
                    // let name = item.hasOwnProperty('name_translate') ? item['name_translate'][lang] : item.name;
                    let name = item.hasOwnProperty('name_translate') && item['name_translate'][lang] ? `${item['name_translate'][lang]}` : `${item.name}`;
                    let serv_name = item?.[`type_serv_name_${lang}`] ? `${item[`type_serv_name_${lang}`]}` : ''
                    switch (item.type) {
                        case "material":
                            return allMaterialOverdue.add(name);
                        case "band":
                            return allBandOverdue.add(name);
                        case "serv":
                            return (
                                allServiceOverdue.add(name) &&
                                allServTypeOverdue.add(serv_name)
                            );
                        default:
                            return false;
                    }
                    // }
                });

                return true;
            }

            return false;
        });
    } else {
        filteredDataOverdue = [];
    }

    let selectedServicesCountOverdue = 0;

    if (servicesFilterOverdue) {
        // считаем кол-во сервисов и фильтруем таблицу по ним
        filteredDataOverdue = filteredDataOverdue.filter((item) => {
            const service = item.calc.service.find(
                (service) => service.name === servicesFilterOverdue
            );

            if (service) {
                selectedServicesCountOverdue += service.count;
                return true;
            }

            return false;
        });
    }

    const handleMaterialChangeOverdue = (event) => {
        setMaterialFilterOverdue({value: event.target.value});
    };
    const handleBandChangeOverdue = (event) => {
        setBandFilterOverdue({value: event.target.value});
    };

    const handleServChangeOverdue = (event) => {
        setServFilterOverdue({value: event.target.value});
    };
    const handleServTypeChangeOverdue = (event) => {
        setServTypeOverdue({value: event.target.value});
    };
    let allBandOverdueArr = Array.from(allBandOverdue);
    let allServiceOverdueArr = Array.from(allServiceOverdue);
    let allMaterialOverdueArr = Array.from(allMaterialOverdue);
    let allServTypeOverdueArr = Array.from(allServTypeOverdue);
    const sortedDataOverdue = filteredDataOverdue.map((elem) => {
        return elem.calc.material;
    });

    function flatDeep(arr, d = 1) {
        return d > 0
            ? arr.reduce(
                (acc, val) =>
                    acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
                []
            )
            : arr.slice();
    }

    const convertInArrayOverdue = flatDeep(sortedDataOverdue, Infinity);

    let sortedByBandOverdue = convertInArrayOverdue.filter((el) => {
            let name = `${el.name}`
            return name?.includes(bandFilterOverdue.value);
        }
    );
    let sortedByMaterialOverdue = convertInArrayOverdue.filter((el) => {
            let name = `${el.name}`
            return name?.includes(materialFilterOverdue.value)
        }
    );
    let sortedByServiceOverdue = convertInArrayOverdue.filter((el) => {
            let name = `${el.name}`
            return name?.includes(servFilterOverdue.value)
        }
    );
    let sortedByServTypeOverdue = convertInArrayOverdue.filter(
        (el) =>
            el[`type_serv_name_${lang}`]?.includes(servTypeOverdue.value) &&
            el.ready_serv !== "1"
    );

    const sortedByMaterialOverdueSum = sortedByMaterialOverdue
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByBandOverdueSum = sortedByBandOverdue
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    const sortedByServiceOverdueSum = sortedByServiceOverdue
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByServTypeOverdueSum = sortedByServTypeOverdue
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    let trimmedServiceOverdue = allServiceOverdueArr?.filter(el => !!el).map(
        (el) => el?.split("(202")[0]
    );
    let trimmedMaterialOverdue = allMaterialOverdueArr?.filter(el => !!el).map(
        (el) => el?.split("(202")[0]
    );

    let trimmedBandOverdue = allBandOverdueArr?.filter(el => !!el).map((el) => {
        return el.split("(202")[0];
    });

    let trimmedServTypeOverdue = allServTypeOverdueArr?.filter(el => !!el).map(
        (el) => el?.split("(202")[0]
    );

    let uniqOverdueServ = [...new Set(trimmedServiceOverdue)];
    let uniqOverdueBand = [...new Set(trimmedBandOverdue)];
    let uniqOverdueMat = [...new Set(trimmedMaterialOverdue)];
    let sortServOverdue = uniqOverdueServ.sort();
    // *************************Просроченные заказы сумма********************//

    const allService = new Set();
    const allMaterial = new Set();
    const allBand = new Set();
    const allServType = new Set();

    let filteredData = [];

    if (props.ordersIn.length > 0) {
        filteredData = props.ordersIn.filter((el) => {
            let query_manager = !searchManager ? true : Number(el.manager?.id) === Number(searchManager);
            const shouldBeShown =
                el.id.includes(searchID) &&
                el.goods_order_without_part === 0 &&
                el.code.includes(searchCode) &&
                el.name.includes(searchName) &&
                el.client.name.toLowerCase().includes(searchClient) &&
                query_manager &&
                (!date[0].from || el.confirmed_date >= date[0].from) &&
                (!date[0].to || el.confirmed_date <= date[0].to) &&
                (!date_production[0].from ||
                    el.plan_of_production >= date_production[0].from) &&
                (!date_production[0].to ||
                    el.plan_of_production <= date_production[0].to);

            if (shouldBeShown) {
                el.calc?.material?.map((item) => {
                    let name = item.hasOwnProperty('name_translate') ? item.name_translate[lang] : item.name;
                    // item.filter((e) => Number(e.ready_serv) !== 1);\
                    if (Number(item.ready_serv) !== 1) {
                        switch (item.type) {
                            case "material":
                                return allMaterial.add(name);
                            case "band":
                                return allBand.add(name);
                            case "serv":
                                return (
                                    allService.add(name) &&
                                    allServType.add(item[`type_serv_name_${lang}`])
                                );
                            default:
                                return false;
                        }
                    }
                });

                return true;
            }

            return false;
        });
    } else {
        filteredData = [];
    }

    let selectedServicesCount = 0;

    if (servicesFilter) {
        // считаем кол-во сервисов и фильтруем таблицу по ним
        filteredData = filteredData.filter((item) => {
            const service = item.calc.service.find(
                (service) => service.name === servicesFilter
            );

            if (service) {
                selectedServicesCount += service.count;
                return true;
            }

            return false;
        });
    }

    const handleMaterialChange = (event) => {
        setMaterialFilter({value: event.target.value});
    };
    const handleBandChange = (event) => {
        setBandFilter({value: event.target.value});
    };

    const handleServChange = (event) => {
        setServFilter({value: event.target.value});
    };
    const handleServTypeChange = (event) => {
        setServType({value: event.target.value});
    };

    let allBandArr = Array.from(allBand);
    let allServiceArr = Array.from(allService);
    let allMaterialArr = Array.from(allMaterial);
    let allServTypeArr = Array.from(allServType);
    const sortedData = filteredData.map((elem) => {
        // elem.calc.material.production_stage_number = elem.production_stage_number;
        return elem?.calc?.material || []
    });

    function flatDeep(arr, d = 1) {
        return d > 0
            ? arr.reduce(
                (acc, val) => {
                    // console.log(val)
                    return acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val)
                },
                []
            )
            : arr.slice();
    }

    const convertInArray = flatDeep(sortedData, Infinity);

    let sortedByBand = convertInArray.filter((el) => {
            if (el.hasOwnProperty('name_translate')) {
                return el.name_translate[lang]?.includes(bandFilter.value)
            } else if (el?.name?.length) {
                return el?.name?.includes(bandFilter.value)
            }
        }
    );
    let sortedByMaterial = convertInArray.filter((el) => {
            if (el.hasOwnProperty('name_translate')) {
                return el.name_translate[lang]?.includes(materialFilter.value)
            } else if (el?.name?.length) {
                return el?.name?.includes(materialFilter.value)
            }

        }
        // el.name.includes(materialFilter.value)
    );
    let sortedByService = convertInArray.filter(
        (el) => {
            if (el.hasOwnProperty('name_translate')) {
                return el.name_translate[lang]?.includes(servFilter.value) && el.ready_serv !== "1"
            } else if (el?.name?.length) {
                return el?.name?.includes(servFilter.value) && el.ready_serv !== "1"
            }


            // el.name.includes(servFilter.value) && el.ready_serv !== "1"
        }
    );
    let sortedByServType = convertInArray.filter(
        (el) => el[`type_serv_name_${lang}`]?.includes(servType.value) && el.ready_serv !== "1"
    );

    const sortedByMaterialSum = sortedByMaterial
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByBandSum = sortedByBand
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByServiceSum = sortedByService
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByServTypeSum = sortedByServType
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    let trimmedService = allServiceArr.map((el) => el?.split("(202")[0]);
    let trimmedMaterial = allMaterialArr.map((el) => el?.split("(202")[0]);
    let trimmedBand = allBandArr.map((el) => el?.split("(202")[0]);
    let trimmedServType = allServTypeArr.map((el) => el?.split("(202")[0]);

    let uniqServ = [...new Set(trimmedService)];
    let uniqBand = [...new Set(trimmedBand)];
    let uniqMat = [...new Set(trimmedMaterial)];
    let sortServ = uniqServ.sort();

    const allServiceFor = new Set();
    const allMaterialFor = new Set();
    const allBandFor = new Set();
    const allServTypeFor = new Set();
    let filteredDataFor;

    if (ok.length > 0 && props.activeTab === 1) {
        filteredDataFor = ok.filter((el) => {
            let query_manager = !searchManager ? true : Number(el.manager?.id) === Number(searchManager);
            const shouldBeShown =
                el.id.includes(searchID) &&
                el.goods_order_without_part === 0 &&
                el.code.includes(searchCode) &&
                el.name.includes(searchName) &&
                (el.edge_type[0] === query[0] ||
                    el.edge_type[0] === query[1] ||
                    el.edge_type[0] === query[2] ||
                    el.edge_type[0] === query[3]) &&
                el.mat.some((mat) => selectedMatrialsValues.includes(mat.name)) &&
                el.serv.some((serv) => selectedServicesValues.includes(serv.name)) &&
                el.glue_type.some((glue) =>
                    selectedGlueValue.includes(glue.type_info)
                ) &&
                el.client.name.toLowerCase().includes(searchClient) &&
                query_manager &&
                el.place_in_order_to_pack >= que &&
                el.parent > prod &&
                el.client.name.toLowerCase().includes(searchClient) &&
                (!date[0].from || el.confirmed_date >= date[0].from) &&
                (!date[0].to || el.confirmed_date <= date[0].to) &&
                (!date_production[0].from ||
                    el.plan_of_production >= date_production[0].from) &&
                (!date_production[0].to ||
                    el.plan_of_production <= date_production[0].to);

            if (shouldBeShown) {
                el.calc.material.map((item) => {
                    if (Number(item.ready_serv) !== 1) {
                        switch (item.type) {
                            case "material":
                                let nameMaterial = item.hasOwnProperty('name_translate') && item['name_translate']?.[lang] ? item['name_translate'][lang] : item.name ? item.name : '';
                                return allMaterialFor.add(nameMaterial);
                            case "band":
                                let nameBand = item.hasOwnProperty('name_translate') && item['name_translate']?.[lang] ? item['name_translate'][lang] : item.name ? item.name : '';

                                return allBandFor.add(nameBand);
                            case "serv":
                                let nameServ = item.hasOwnProperty('name_translate') && item['name_translate']?.[lang] ? item['name_translate'][lang] : item?.name ? item.name : '';
                                return (
                                    allServiceFor.add(nameServ) &&
                                    allServTypeFor.add(item[`type_serv_name_${lang}`])
                                );
                            default:
                                return false;
                        }
                    }
                });

                return true;
            }

            return false;
        });
    } else {
        filteredDataFor = [];
    }

    let selectedServicesCountFor = 0;

    if (servicesFilterFor) {
        // считаем кол-во сервисов и фильтруем таблицу по ним
        filteredDataFor = filteredDataFor.filter((item) => {
            const service1 = item.calc.service.find(
                (service) => service.name === servicesFilterFor
            );

            if (service1) {
                selectedServicesCountFor += service1.count;
                return true;
            }

            return false;
        });
    }
    const handleMaterialChangeFor = (event) => {
        setMaterialFilterFor({value: event.target.value});
    };
    const handleBandChangeFor = (event) => {
        setBandFilterFor({value: event.target.value});

    };

    const handleServChangeFor = (event) => {
        setServFilterFor({value: event.target.value});

    };
    const handleServTypeChangeFor = (event) => {
        setServTypeFor({value: event.target.value});

    };

    let allBandArrFor = Array.from(allBandFor);
    let allServiceArrFor = Array.from(allServiceFor);
    let allMaterialArrFor = Array.from(allMaterialFor);
    let allServTypeArrFor = Array.from(allServTypeFor);
    const sortedDataFor = filteredDataFor.map((elem) => {
        // добавила признак очереди для верного просчета суммы
        // return elem.calc.material;
        return elem;
    });

    const sortedDataForMaterial = filteredDataFor.map((elem) => {
        // добавила признак очереди для верного просчета суммы
        return elem.calc.material;
        // return elem;
    });
    const convertInArrayFor = flatDeep(sortedDataFor, Infinity);
    const convertInArrayForMaterial = flatDeep(sortedDataForMaterial, Infinity);
    let sortedByBandFor = convertInArrayForMaterial.filter((el) => {
            return el?.hasOwnProperty('name_translate') ? el['name_translate'][lang]?.includes(bandFilterFor?.value) : el?.name ? el?.name?.includes(bandFilterFor?.value) : []
        }
    );
    let sortedByMaterialFor = convertInArrayForMaterial.filter((el) => {
            return el?.hasOwnProperty('name_translate') ? el['name_translate'][lang]?.includes(materialFilterFor?.value) : el?.name ? el.name?.includes(materialFilterFor?.value) : []

            // return  el.name ? el.name.includes(materialFilterFor.value) : []
        }
    );
    let sortedByServiceFor = convertInArrayForMaterial.filter((el) => {
            return el?.hasOwnProperty('name_translate') ? el['name_translate'][lang]?.includes(servFilterFor?.value) : el?.name ? el?.name?.includes(servFilterFor?.value) : []

            // return el.name ? el.name.includes(servFilterFor.value) : []
        }
    );
    let sortedByServTypeFor = convertInArrayFor.map((el) => {
            switch (Number(que)) {
                case 1: {
                    if (el.production_stage_number >= 1) {
                        return el.calc.material.filter(el => {
                            return el[`type_serv_name_${lang}`]?.includes(servTypeFor.value)
                        });
                    }
                    break;
                }
                case 0: {
                    if (el.production_stage_number == 0) {
                        return el.calc.material.filter(el => {
                            return el[`type_serv_name_${lang}`]?.includes(servTypeFor.value)
                        });
                    }
                    break;

                }
                default: {
                    return el.calc.material.filter(item => {
                        return item[`type_serv_name_${lang}`]?.includes(servTypeFor.value)
                    });
                }

            }

            // return  el.calc.material.filter( item => {
            //
            //   return item.type_serv_name_ru?.includes(servTypeFor.value)
            // })

        }
    );
    const sortedByServTypeForValues = flatDeep(sortedByServTypeFor, Infinity);

    const sortedByMaterialSumFor = sortedByMaterialFor
        .reduce((n, {count}) => n + (count) ? count : 0, 0)
        .toFixed(2);
    const sortedByBandSumFor = sortedByBandFor
        .reduce((n, {count}) => n + (count) ? count : 0, 0)
        .toFixed(2);
    const sortedByServiceSumFor = sortedByServiceFor
        .reduce((n, {count}) => {
            return n + (count) ? count : 0
        }, 0)
        .toFixed(2);
    // const sortedByServTypeSumFor = sortedByServTypeFor
    const sortedByServTypeSumFor = sortedByServTypeForValues
        .reduce((n, el = {count: 0}) => {
            return n + el.count
        }, 0)
        .toFixed(2);

    let trimmedServiceFor = allServiceArrFor.map((el) => el?.split("(202")[0]);
    let trimmedMaterialFor = allMaterialArrFor.map((el) => el?.split("(202")[0]);
    let trimmedBandFor = allBandArrFor.map((el) => el?.split("(202")[0]);
    let trimmedServTypeFor = allServTypeArrFor.map((el) => el?.split("(202")[0]);

    let uniqServFor = [...new Set(trimmedServiceFor)];
    let uniqBandFor = [...new Set(trimmedBandFor)];
    let uniqMatFor = [...new Set(trimmedMaterialFor)];
    let sortServFor = uniqServFor.sort();

    // **********************
    const allServiceBad = new Set();
    const allMaterialBad = new Set();
    const allBandBad = new Set();
    const allServTypeBad = new Set();
    let filteredDataBad;

    if (bad.length > 0 && props.activeTab === 5) {
        filteredDataBad = bad.filter((el) => {
            let query_manager = !searchManager ? true : Number(el.manager?.id) === Number(searchManager);
            const shouldBeShown =
                el.id.includes(searchID) &&
                el.goods_order_without_part === 0 &&
                el.code.includes(searchCode) &&
                el.name.includes(searchName) &&
                el.client.name.toLowerCase().includes(searchClient) &&
                query_manager &&
                (!date[0].from || el.confirmed_date >= date[0].from) &&
                (!date[0].to || el.confirmed_date <= date[0].to) &&
                (!date_production[0].from ||
                    el.plan_of_production >= date_production[0].from) &&
                (!date_production[0].to ||
                    el.plan_of_production <= date_production[0].to);

            if (shouldBeShown) {
                el.calc.material.map((item) => {
                    let name = item.hasOwnProperty('name_translate') ? item['name_translate'][lang] : item.name;
                    if (Number(item.ready_serv) !== 1) {
                        switch (item.type) {
                            case "material":
                                return allMaterialBad.add(name);
                            case "band":
                                return allBandBad.add(name);
                            case "serv":
                                return (
                                    allServiceBad.add(name) &&
                                    allServTypeBad.add(item[`type_serv_name_${lang}`])
                                );
                            default:
                                return false;
                        }
                    }
                });

                return true;
            }

            return false;
        });
    } else {
        filteredDataBad = [];
    }

    let selectedServicesCountBad = 0;

    if (servicesFilterFor) {
        // считаем кол-во сервисов и фильтруем таблицу по ним
        filteredDataBad = filteredDataBad.filter((item) => {
            const service1 = item.calc.service.find(
                (service) => service.name === servicesFilterFor
            );

            if (service1) {
                selectedServicesCountBad += service1.count;
                return true;
            }

            return false;
        });
    }
    const handleMaterialChangeBad = (event) => {
        setMaterialFilterBad({value: event.target.value});
    };
    const handleBandChangeBad = (event) => {
        setBandFilterBad({value: event.target.value});
    };

    const handleServChangeBad = (event) => {
        setServFilterBad({value: event.target.value});
    };
    const handleServTypeChangeBad = (event) => {
        setServTypeBad({value: event.target.value});
    };

    let allBandArrBad = Array.from(allBandBad);
    let allServiceArrBad = Array.from(allServiceBad);
    let allMaterialArrBad = Array.from(allMaterialBad);
    let allServTypeArrBad = Array.from(allServTypeBad);
    const sortedDataBad = filteredDataBad.map((elem) => {
        return elem.calc.material;
    });

    const convertInArrayBad = flatDeep(sortedDataBad, Infinity);
    let sortedByBandBad = convertInArrayBad.filter((el) => {
            if (el.hasOwnProperty('name_translate')) {
                el['name_translate'][lang].includes(bandFilterBad.value)

            } else if (el?.name) {
                return el?.name?.includes(bandFilterBad?.value)

            }
            // return el.name.includes(bandFilterBad.value)
        }
    );
    let sortedByMaterialBad = convertInArrayBad.filter((el) => {
            if (el.hasOwnProperty('name_translate')) {
                return el['name_translate'][lang].includes(materialFilterBad.value);

            } else if (el?.name) {
                return el?.name?.includes(materialFilterBad?.value)
            }
        }
        // el.name.includes(materialFilterBad.value)
    );
    let sortedByServiceBad = convertInArrayBad.filter((el) => {
            if (el.hasOwnProperty('name_translate')) {
                return el['name_translate'][lang].includes(servFilterBad.value)

            } else if (el?.name) {
                return el?.name?.includes(servFilterBad?.value)
            }
            // return el.name.includes(servFilterBad.value)
        }
    );
    let sortedByServTypeBad = convertInArrayBad.filter((el) => {
            return el[`type_serv_name_${lang}`]?.includes(servTypeBad.value)
        }
    );
    const sortedByMaterialSumBad = sortedByMaterialBad
        .reduce((n, {count}) => {
            return n + count
        }, 0)
        .toFixed(2);
    const sortedByBandSumBad = sortedByBandBad
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByServiceSumBad = sortedByServiceBad
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);
    const sortedByServTypeSumBad = sortedByServTypeBad
        .reduce((n, {count}) => n + count, 0)
        .toFixed(2);

    let trimmedServiceBad = allServiceArrBad?.filter(el => el)?.map((el) => el?.split("(202")?.[0]);
    let trimmedMaterialBad = allMaterialArrBad?.filter(el => el)?.map((el) => el?.split("(202")?.[0]);
    let trimmedBandBad = allBandArrBad?.filter(el => el)?.map((el) => el?.split("(202")?.[0]);
    let trimmedServTypeBad = allServTypeArrBad?.filter(el => el)?.map((el) => el?.split("(202")?.[0]);
    let uniqServBad = [...new Set(trimmedServiceBad)];
    let uniqBandBad = [...new Set(trimmedBandBad)];
    let uniqMatBad = [...new Set(trimmedMaterialBad)];
    let sortServBad = uniqServBad.sort();

    //********************** */
    // --------------------Сортировка-----------------------------------------//
    const [sortType, setSortType] = useState("urgent");
    const [data, setData] = useState([]);
    const [dataBad, setDataBad] = useState([]);
    const [dataIn, setDataIn] = useState([]);
    const [force_key, setForceKey] = useState(uuid())
    const forceOrders = () => {
        setForceKey(uuid())
    }
    useEffect(() => {
        const sortArray = (type) => {
            const types = {
                confirmed_date_up: "confirmed_date_up",
                confirmed_date_down: "confirmed_date_down",
                plan_of_production_up: "plan_of_production_up",
                plan_of_production_down: "plan_of_production_down",
                production_stage_number_up: "production_stage_number_up",
                production_stage_number_down: "production_stage_number_down",
                service_summ_up: "service_summ_up",
                service_summ_down: "service_summ_down",
                summ_order_up: "summ_order_up",
                summ_order_down: "summ_order_down",
                urgent: "urgent"
            };
            let sortProperty = types[type];
            if (sortProperty === "confirmed_date_up") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredDataFor].sort((a, b) =>
                    a[sortProperty].localeCompare(b[sortProperty])
                );
                setData(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "confirmed_date_down") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredDataFor]?.sort((a, b) =>
                    b[sortProperty].localeCompare(a[sortProperty])
                );

                setData(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "plan_of_production_up") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredDataFor]?.sort((a, b) => {
                        return new Date(a[sortProperty]) - new Date(b[sortProperty]);
                    }
                );

                setData(sorted);
            } else if (sortProperty === "plan_of_production_down") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredDataFor]?.sort((a, b) => {
                        return new Date(b[sortProperty]) - new Date(a[sortProperty]);
                    }
                );
                // const sorted = [...filteredDataFor].sort((a, b) =>
                //     b[sortProperty].localeCompare(a[sortProperty])
                // );
                setData(sorted);
            } else if (sortProperty === "production_stage_number_up") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredDataFor]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setData(sorted);
            } else if (sortProperty === "production_stage_number_down") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredDataFor].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setData(sorted);
            } else if (sortProperty === "service_summ_up") {
                sortProperty = "service_summ";
                const sorted = [...filteredDataFor]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setData(sorted);
            } else if (sortProperty === "service_summ_down") {
                sortProperty = "service_summ";
                const sorted = [...filteredDataFor].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setData(sorted);
            } else if (sortProperty === "summ_order_up") {
                sortProperty = "summ_order";
                const sorted = [...filteredDataFor]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setData(sorted);
            } else if (sortProperty === "summ_order_down") {
                sortProperty = "summ_order";
                const sorted = [...filteredDataFor].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setData(sorted);
            } else if (sortProperty === "urgent") {
                sortProperty = "urgent";
                const sorted = [...filteredDataFor].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                debugger;

                setData(sorted);
            }
        };

        sortArray(sortType);
    }, [sortType, filteredDataFor.length, props.ordersFor]);

    useEffect(() => {
        const sortArray = (type) => {
            const types = {
                confirmed_date_up: "confirmed_date_up",
                confirmed_date_down: "confirmed_date_down",
                plan_of_production_up: "plan_of_production_up",
                plan_of_production_down: "plan_of_production_down",
                production_stage_number_up: "production_stage_number_up",
                production_stage_number_down: "production_stage_number_down",
                service_summ_up: "service_summ_up",
                service_summ_down: "service_summ_down",
                summ_order_up: "summ_order_up",
                summ_order_down: "summ_order_down",
                urgent: "urgent"
            };
            let sortProperty = types[type];

            if (sortProperty === "confirmed_date_up") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredDataBad].sort((a, b) =>
                    a[sortProperty].localeCompare(b[sortProperty])
                );
                setDataBad(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "confirmed_date_down") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredDataBad]?.sort((a, b) =>
                    b[sortProperty].localeCompare(a[sortProperty])
                );

                setDataBad(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "plan_of_production_up") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredDataBad]?.sort((a, b) =>
                    a[sortProperty].localeCompare(b[sortProperty])
                );

                setDataBad(sorted);
            } else if (sortProperty === "plan_of_production_down") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredDataBad].sort((a, b) =>
                    b[sortProperty].localeCompare(a[sortProperty])
                );
                setDataBad(sorted);
            } else if (sortProperty === "production_stage_number_up") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredDataBad]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataBad(sorted);
            } else if (sortProperty === "production_stage_number_down") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredDataBad].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataBad(sorted);
            } else if (sortProperty === "service_summ_up") {
                sortProperty = "service_summ";
                const sorted = [...filteredDataBad]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataBad(sorted);
            } else if (sortProperty === "service_summ_down") {
                sortProperty = "service_summ";
                const sorted = [...filteredDataBad].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataBad(sorted);
            } else if (sortProperty === "summ_order_up") {
                sortProperty = "summ_order";
                const sorted = [...filteredDataBad]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataBad(sorted);
            } else if (sortProperty === "summ_order_down") {
                sortProperty = "summ_order";
                const sorted = [...filteredDataBad].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataBad(sorted);
            } else if (sortProperty === "urgent") {
                sortProperty = "urgent";
                const sorted = [...filteredDataBad].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );

                setDataBad(sorted);
            }
        };

        sortArray(sortType);
    }, [sortType, filteredDataBad.length]);

    useEffect(() => {
        const sortArray = (type) => {
            const types = {
                confirmed_date_up: "confirmed_date_up",
                confirmed_date_down: "confirmed_date_down",
                plan_of_production_up: "plan_of_production_up",
                plan_of_production_down: "plan_of_production_down",
                production_stage_number_up: "production_stage_number_up",
                production_stage_number_down: "production_stage_number_down",
                service_summ_up: "service_summ_up",
                service_summ_down: "service_summ_down",
                summ_order_up: "summ_order_up",
                summ_order_down: "summ_order_down",
                urgent: "urgent"
            };
            let sortProperty = types[type];

            if (sortProperty === "confirmed_date_up") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredData].sort((a, b) =>
                    a[sortProperty].localeCompare(b[sortProperty])
                );
                setDataIn(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "confirmed_date_down") {
                sortProperty = "confirmed_date";
                const sorted = [...filteredData]?.sort((a, b) =>
                    b[sortProperty].localeCompare(a[sortProperty])
                );

                setDataIn(sorted);
                sortProperty = types[type];
            } else if (sortProperty === "plan_of_production_up") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredData]?.sort((a, b) =>
                    a[sortProperty].localeCompare(b[sortProperty])
                );

                setDataIn(sorted);
            } else if (sortProperty === "plan_of_production_down") {
                sortProperty = "plan_of_production";
                const sorted = [...filteredData].sort((a, b) =>
                    b[sortProperty].localeCompare(a[sortProperty])
                );
                setDataIn(sorted);
            } else if (sortProperty === "production_stage_number_up") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredData]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataIn(sorted);
            } else if (sortProperty === "production_stage_number_down") {
                sortProperty = "production_stage_number";
                const sorted = [...filteredData].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataIn(sorted);
            } else if (sortProperty === "service_summ_up") {
                sortProperty = "service_summ";
                const sorted = [...filteredData]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataIn(sorted);
            } else if (sortProperty === "service_summ_down") {
                sortProperty = "service_summ";
                const sorted = [...filteredData].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataIn(sorted);
            } else if (sortProperty === "summ_order_up") {
                sortProperty = "summ_order";
                const sorted = [...filteredData]?.sort(
                    (a, b) => a[sortProperty] - b[sortProperty]
                );

                setDataIn(sorted);
            } else if (sortProperty === "summ_order_down") {
                sortProperty = "summ_order";
                const sorted = [...filteredData].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataIn(sorted);
            } else if (sortProperty === "urgent") {
                sortProperty = "urgent";
                const sorted = [...filteredData].sort(
                    (a, b) => b[sortProperty] - a[sortProperty]
                );
                setDataIn(sorted);
            }
        };

        sortArray(sortType);
    }, [sortType, filteredData.length, force_key]);

    // --------------------Сортировка-----------------------------------------//


    //задать значение календарю
    //задать значение очереди происзводства
    const [valueProductionLines, setValueProductionLines] = useState(null)
    // const setValueProductionLines = (startValue)=>{

    // return values;
    // }
    const initialTransferOrder = {
        ids: [],
        from_status: 3,
        to_status: null
    }
    const [transfer_group_order, setTransferOrder] = useState(initialTransferOrder);

    const onChangeTransferOrder = (id_order) => {
        if (!id_order) return
        let order_ids = transfer_group_order.ids;
        var index = order_ids.indexOf(id_order);
        if (index === -1) {
            order_ids.push(id_order);
        } else {
            order_ids.splice(index, 1);
        }
        setTransferOrder((prev) => ({
            ...prev, ids: order_ids
        }));
    }
    const ACTIVE_TAB_CONNECT_STATUS = {
        'tab_1': 3,
        'tab_5': null,
        'tab_2': 4,
        'tab_3': 5,
        'tab_4': 6
    }
    const resetTransferOrder = () => {
        let from_status = ACTIVE_TAB_CONNECT_STATUS[`tab_${props.activeTab}`]
        setTransferOrder((prev) => ({
            ...initialTransferOrder,
            from_status: from_status
        }));
    }
    useEffect(() => {
        let from_status = ACTIVE_TAB_CONNECT_STATUS[`tab_${props.activeTab}`]
        if (props.activeTab && from_status) {
            setTransferOrder((prev) => ({
                ...initialTransferOrder,
                from_status: from_status
            }))
        }
    }, [props.activeTab]);

    return (
        <div className="content-wrapper" key={force_key}>
            <PageTitle title={"orders_production"}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="gap_8 d-flex mb-2 align-items-center">

                            <SettingsCookies getT={props.getT}/>
                            <div className="d-flex items-center">
                                <label className={'mb-0 mr-1'}>   {props.getT("Краткий")}</label>
                                <div className="toggle-pill mb-0">
                                    <input type="checkbox" id={'mode'}
                                           checked={isManualMode}
                                           value={isManualMode}
                                           onChange={(event) => {
                                               onToggleDisplayAll(event.target.checked)
                                           }}/>
                                    <label htmlFor="mode" className={'mb-0'}></label>
                                </div>
                            </div>


                            {(props.activeTab !== 5) &&
                                <GroupTransfer getT={props.getT} data_group_transfer={transfer_group_order}
                                               resetTransferOrder={resetTransferOrder}
                                               forceOrders={forceOrders}
                                />}

                            {props.activeTab === 2 && <>
                                <button
                                    className=" btn btn-sm  btn-secondary"
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            production_task_btn: 'Сумма'
                                        })
                                        setShowFiltersModal(true);
                                    }}
                                >
                                    {props.getT("Сумма")}
                                </button>
                                <button

                                    className=" btn btn-sm  btn-secondary"
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            production_task_btn: 'Сумма просроченные'
                                        })
                                        setShowFiltersModalOverdue(true);
                                    }}
                                >
                                    {props.getT("Сумма просроченные")}
                                </button>
                            </>}


                            {props.activeTab === 1 && (<>

                                    <button
                                        className=" btn btn-sm  btn-secondary"
                                        onClick={() => {
                                            setShowFiltersModalChekedOrders(true);
                                            setProdTaskOrders(checkedOrders.map((el) => el.id));
                                        }}
                                    >{props.getT("В производство")}</button>

                                    <button
                                        className=" btn btn-sm  btn-secondary"
                                        onClick={() => {
                                            setShowFiltersModalFor(true);
                                        }}
                                    >
                                        {props.getT("Сумма")}
                                    </button>
                                </>
                            )}

                            {props.activeTab === 5 && (
                                <button
                                    className=" btn btn-sm  btn-secondary"
                                    onClick={() => {
                                        setShowFiltersModalBad(true);
                                    }}
                                >
                                    {props.getT("Сумма")}
                                </button>
                            )}
                            <Modal
                                open={showFiltersModalBad}
                                onClose={() => {
                                    setShowFiltersModalBad(false);
                                    setBandFilterBad("");
                                    setMaterialFilterBad("");
                                    setServFilterBad("");
                                    setServTypeBad("");
                                }}
                            >
                                <>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество кромки")}:</label>
                                            <select className="form-control" onChange={handleBandChangeBad}>
                                                <option>{props.getT("Выберите кромку")}</option>
                                                {uniqBandBad.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {bandFilterBad.value && (
                                            <h2>
                                                {sortedByBandSumBad} {sortedByBandBad[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг по типу")}: </label>
                                            <select
                                                className="form-control"
                                                onChange={handleServTypeChangeBad}
                                            >
                                                <option>{props.getT("Выберите тип услуги")}:</option>
                                                {trimmedServTypeBad.map((el) => {
                                                    return (<option key={el} value={el}>
                                                            {el}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {servTypeBad.value && (
                                            <h2>
                                                {sortedByServTypeSumBad} {sortedByServTypeBad[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество материала")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleMaterialChangeBad}
                                            >
                                                <option>{props.getT("Выберите материал")}</option>
                                                {uniqMatBad.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {materialFilterBad.value && (
                                            <h2>
                                                {sortedByMaterialSumBad} {sortedByMaterialBad[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг")}:</label>
                                            <select className="form-control" onChange={handleServChangeBad}>
                                                <option>{props.getT("Выберите услугу")}</option>
                                                {sortServBad.map((el, idx) => (
                                                    <option key={idx} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {servFilterBad.value && (
                                            <h2>
                                                {sortedByServiceSumBad} {sortedByServiceBad[0]?.unit}{" "}
                                            </h2>
                                        )}
                                    </div>
                                </>
                            </Modal>

                            <Modal
                                open={showFiltersModalOverdue}
                                onClose={() => {
                                    setShowFiltersModalOverdue(false);
                                    setBandFilterOverdue("");
                                    setMaterialFilterOverdue("");
                                    setServFilterOverdue("");
                                    setServTypeOverdue("");
                                }}
                            >
                                <>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество кромки")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleBandChangeOverdue}
                                            >
                                                <option>{props.getT("Выберите кромку")}</option>
                                                {uniqOverdueBand.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {bandFilterOverdue.value && (
                                            <h2>
                                                {sortedByBandOverdueSum} {sortedByBandOverdue[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг по типу")}: </label>
                                            <select
                                                className="form-control"
                                                onChange={handleServTypeChangeOverdue}
                                            >
                                                <option>{props.getT("Выберите тип услуги")}:</option>
                                                {allServTypeOverdueArr.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {servTypeOverdue.value && (
                                            <h2>
                                                {sortedByServTypeOverdueSum}{" "}
                                                {sortedByServTypeOverdue[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество материала")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleMaterialChangeOverdue}
                                            >
                                                <option>{props.getT("Выберите материал")}</option>
                                                {uniqOverdueMat.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {materialFilterOverdue.value && (
                                            <h2>
                                                {sortedByMaterialOverdueSum}{" "}
                                                {sortedByMaterialOverdue[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleServChangeOverdue}
                                            >
                                                <option>{props.getT("Выберите услугу")}</option>
                                                {sortServOverdue.map((el, idx) => (
                                                    <option key={idx} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {servFilterOverdue.value && (
                                            <h2>
                                                {sortedByServiceOverdueSum} {sortedByServiceOverdue[0]?.unit}{" "}
                                            </h2>
                                        )}
                                    </div>
                                </>
                            </Modal>

                            <Modal
                                open={showFiltersModal}
                                onClose={() => {
                                    setShowFiltersModal(false);
                                    setBandFilter("");
                                    setMaterialFilter("");
                                    setServFilter("");
                                    setServType("");
                                }}
                            >
                                <>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество кромки")}:</label>
                                            <select className="form-control" onChange={handleBandChange}>
                                                <option>{props.getT("Выберите кромку")}</option>
                                                {uniqBand.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {bandFilter.value && (
                                            <h2>
                                                {sortedByBandSum} {sortedByBand[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг по типу")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleServTypeChange}
                                            >
                                                <option>{props.getT("Выберите тип услуги")}:</option>
                                                {trimmedServType.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {servType.value && (
                                            <h2>
                                                {sortedByServTypeSum} {sortedByServType[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество материала")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleMaterialChange}
                                            >
                                                <option>{props.getT("Выберите материал")}</option>
                                                {uniqMat.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {materialFilter.value && (
                                            <h2>
                                                {sortedByMaterialSum} {sortedByMaterial[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг")}:</label>
                                            <select className="form-control" onChange={handleServChange}>
                                                <option>-------</option>
                                                {sortServ.map((el, idx) => {
                                                    return <option key={idx} value={el}>
                                                        {el}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                        {servFilter.value && (
                                            <h2>
                                                {sortedByServiceSum} {sortedByService[0]?.unit}{" "}
                                            </h2>
                                        )}
                                    </div>
                                </>
                            </Modal>

                            <Modal
                                open={showFiltersModalChekedOrders}
                                onClose={() => {
                                    setShowFiltersModalChekedOrders(false);
                                    setProdTaskOrders();
                                }}
                            >
                                <>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>{props.getT("ID")}</th>
                                            <th></th>
                                            <th>{props.getT("Название")}</th>
                                            <th>{props.getT("Клиент")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {checkedOrders.map((el) => {
                                            return (
                                                <tr>
                                                    <td>{el.id}</td>
                                                    <td></td>
                                                    <td>{el.name}</td>
                                                    <td>{el.client.name} </td>
                                                </tr>
                                            );
                                        })}
                                        {checkedOrders.length > 0 && (
                                            <>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="form-group">
                                                            <label>{props.getT("Действие")}:</label>
                                                            <select
                                                                className="form-control"
                                                                onChange={(e) => setAction(e.target.value)}
                                                            >
                                                                <option value="">--------</option>
                                                                {actionTypes.map((el, idx) => (
                                                                    <option key={idx} value={el.action}>
                                                                        {el.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </td>

                                                    {action === "add-orders" && (
                                                        <td colSpan="2">
                                                            <div className="form-group">
                                                                <label>{props.getT("Доступные производственные задания")}</label>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => setTask(e.target.value)}
                                                                    value={task}
                                                                >
                                                                    <option value="">
                                                                        {props.getT("Выберите доступное производственные задания")}
                                                                    </option>
                                                                    {props.productionTypes.map(
                                                                        (el) =>
                                                                            el.status === "1" && (
                                                                                <option key={el.id} value={el.id}>
                                                                                    {el.name}
                                                                                </option>
                                                                            )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {action === "create" && (
                                                        <td colSpan="2">
                                                            <div className="form-group">
                                                                <label>{props.getT("Имя производственного задания")}:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="prodName"
                                                                    value={prodName}
                                                                    onChange={(e) => setProdName(e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                                {action === 'send-orders-production' ?
                                                    <tr>
                                                        <td colSpan="4">
                                                            <button

                                                                onClick={sendOrderProductionHandler}
                                                            >
                                                                {props.getT("Производственные задание")}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <td colSpan="4">
                                                            <button
                                                                disabled={
                                                                    (action === "create" && prodOrders.name === "") ||
                                                                    (action === "add-orders" && prodOrders.task === "")
                                                                }
                                                                onClick={prodOrdersReq}
                                                            >
                                                                {props.getT("Производственные задание")}
                                                            </button>
                                                        </td>
                                                    </tr>}
                                            </>
                                        )}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan="4">
                                                <h4>{props.getT("Сумма по услугам")}:</h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{props.getT("Порезка")}: {sortedByCutSum} {props.getT("м.пог.")}</td>
                                            <td>
                                                {props.getT("Кромкование прямолинейное")}: {sortedbyStraight_edgeSum} {props.getT("м.пог.")}
                                            </td>
                                            <td>{props.getT("Сверление")}: {sortedbyDrilingSum} {props.getT("шт.")}</td>
                                            <td>{props.getT("Сшивка")}: {sortedbyStitchingSum} {props.getT("шт.")}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {props.getT("Кромкование криволинейное")}: {sortedbyСurvilinearSum} {props.getT("м.пог.")}
                                            </td>
                                            <td>{props.getT("Фрезерование")}: {sortedbyMillingSum} {props.getT("м2")}</td>
                                            <td>{props.getT("Пазование")}: {sortedbyPazSum} {props.getT("м.пог.")}</td>
                                            <td>{props.getT("Урезка")}: {sortedbyTruncationSum} {props.getT("м.пог.")}</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </>
                            </Modal>

                            <Modal
                                open={showFiltersModalFor}
                                onClose={() => {
                                    setShowFiltersModalFor(false);
                                    setBandFilterFor("");
                                    setMaterialFilterFor("");
                                    setServFilterFor("");
                                    setServTypeFor("");
                                }}
                            >
                                <>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество кромки")}:</label>
                                            <select className="form-control" onChange={handleBandChangeFor}>
                                                <option>{props.getT("Выберите кромку")}</option>
                                                {uniqBandFor.map((el) => {
                                                    return <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                        {bandFilterFor.value && (
                                            <h2>
                                                {sortedByBandSumFor} {sortedByBandFor[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг по типу")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleServTypeChangeFor}
                                            >
                                                <option>{props.getT("Выберите тип услуги")}:</option>
                                                {trimmedServTypeFor.map((el) => {
                                                    return <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                        {servTypeFor.value && (
                                            <h2>
                                                {sortedByServTypeSumFor} {sortedByServTypeFor[0]?.unit} {sortedByServTypeForValues[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество материала")}:</label>
                                            <select
                                                className="form-control"
                                                onChange={handleMaterialChangeFor}
                                            >
                                                <option>{props.getT("Выберите материал")}</option>
                                                {uniqMatFor.map((el) => (
                                                    <option key={el} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {materialFilterFor.value && (
                                            <h2>
                                                {sortedByMaterialSumFor} {sortedByMaterialFor[0]?.unit}
                                            </h2>
                                        )}
                                    </div>

                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>{props.getT("Количество услуг")}:</label>
                                            <select className="form-control" onChange={handleServChangeFor}>
                                                <option>{props.getT("Выберите услугу")}</option>
                                                {sortServFor.map((el, idx) => (
                                                    <option key={idx} value={el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {servFilterFor.value && (
                                            <h2>
                                                {sortedByServiceSumFor} {sortedByServiceFor[0]?.unit}{" "}
                                            </h2>
                                        )}
                                    </div>
                                </>
                            </Modal>
                        </div>
                        <div className="card ">
                            <form className={'production-filter'}>
                                <div className="card-body pt-2 pb-2">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-2 col-xl-1">
                                            <div className="form-group">
                                                <label>{props.getT("ID заказа")}:</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="id"
                                                    value={searchID}
                                                    onChange={(e) => setSearchID(e.target.value)}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_production', {
                                                            field_focus: 'ID заказа'
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1 col-xl-data">
                                            <div className="form-group">
                                                {visibleClearDate && (
                                                    <i
                                                        style={{marginRight: "10px"}}
                                                        onClick={clearData}
                                                        class="fas fa-ban "
                                                    >
                                                        {props.getT("Сбросить")}
                                                    </i>
                                                )}

                                                <i
                                                    style={{
                                                        fontSize: "24px",
                                                        color: "red",
                                                        cursor: "pointer"
                                                    }}
                                                    class="fa fa-refresh fa-lg"
                                                ></i>
                                                <label>{props.getT("Дата")}: </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={`${date[0]?.from || ""} ... ${date[0]?.to || ""}`}
                                                    onClick={toggleOpenDatePicker}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_production', {
                                                            field_focus: 'Дата'
                                                        })
                                                    }}
                                                />

                                                {openDatePicker && (
                                                    <div style={{zIndex: 20, position: "absolute"}}>
                                                        <DateRange
                                                            locale={rdrLocales[lang]}
                                                            editableDateInputs={true}
                                                            onChange={(item) => setDate([item.selection])}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={date}
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className="btn-container"
                                                        >
                                                            <i
                                                                i
                                                                style={{cursor: "pointer"}}
                                                                onClick={saveData}
                                                                class="fas fa-check-circle"
                                                            >
                                                                {props.getT("Применить")}
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-1 col-xl-data">
                                            <div className="form-group">
                                                {visibleClearDate_production && (
                                                    <i
                                                        style={{marginRight: "10px", cursor: "pointer"}}
                                                        onClick={clearData_production}
                                                        class="fas fa-ban "
                                                    >
                                                        {props.getT("Сбросить")}
                                                    </i>
                                                )}
                                                <label
                                                    title={props.getT("Дата производства")}>{props.getT("Дата производства")}: </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={`${date_production[0]?.from || ""} ... ${
                                                        date_production[0]?.to || ""
                                                    }`}
                                                    onClick={toggleOpenDate_productionPicker}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_production', {
                                                            field_focus: 'Дата производства'
                                                        })
                                                    }}
                                                ></input>
                                                {openDate_productionPicker && (
                                                    <div style={{zIndex: 20, position: "absolute"}}>
                                                        <DateRange
                                                            locale={rdrLocales[lang]}
                                                            // locale={registerLocale("ru")}
                                                            editableDateInputs={true}
                                                            onChange={(item) =>
                                                                setDate_production([item.selection])
                                                            }
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={date_production}
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className="btn-container"
                                                        >
                                                            <i
                                                                style={{cursor: "pointer"}}
                                                                onClick={saveData_production}
                                                                class="fas fa-check-circle"
                                                            >
                                                                {props.getT("Применить")}
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xl-data">
                                            <div className="form-group">
                                                <label>{props.getT("Название")}:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="name"
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_production', {
                                                            field_focus: 'Название'
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label>{props.getT("Менеджер")}:</label>
                                                <select name="" id="" className={'form-control'} value={searchManager}
                                                        onChange={(event) => {
                                                            console.log(
                                                                'change', event.target.value
                                                            )
                                                            setSearchManager(event.target.value)
                                                        }}>
                                                    <option value={''} selected={true}
                                                    >------
                                                    </option>
                                                    {props.managers?.map(manager => {

                                                        return <option value={manager?.id}>{manager?.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label>{props.getT("Клиент")}:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="client"
                                                    value={searchClient}
                                                    onChange={(e) => setSearchClient(e.target.value)}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_production', {
                                                            field_focus: 'Клиент'
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {(props.activeTab === 1 ||
                                            props.activeTab === 2 ||
                                            props.activeTab === 5) && (
                                            <div className="col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Сортировка")}:</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            gaEvents.customEvent('form_order_production', {
                                                                field_focus: 'Сортировка'
                                                            })
                                                            setSortType(e.target.value)
                                                        }}
                                                    >
                                                        <option value="urgent">{props.getT("По Срочности")}</option>
                                                        <option value="confirmed_date_up">
                                                            {props.getT("Дата подтверждение по возростанию")}
                                                        </option>

                                                        <option value="confirmed_date_down">
                                                            {props.getT("Дата подтверждение по убыванию")}
                                                        </option>
                                                        <option value="plan_of_production_up">
                                                            {props.getT("Дата производства по возростанию")}
                                                        </option>
                                                        <option value="plan_of_production_down">
                                                            {props.getT("Дата производства по убыванию")}
                                                        </option>
                                                        <option value="production_stage_number_up">
                                                            {props.getT("Очередь производства по возростанию")}
                                                        </option>
                                                        <option value="production_stage_number_down">
                                                            {props.getT("Очередь производства по убыванию")}
                                                        </option>
                                                        <option value="service_summ_up">
                                                            {props.getT("Количество услугу по возростанию")}
                                                        </option>
                                                        <option value="service_summ_down">
                                                            {props.getT("Количество услугу по убыванию")}
                                                        </option>
                                                        <option value="summ_order_up">
                                                            {props.getT("Сумма заказа по возростанию")}
                                                        </option>
                                                        <option value="summ_order_down">
                                                            {props.getT("Сумма заказа по убыванию")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {props.activeTab === 1 && (
                                        <div className="row">
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Тип кромкования")}:</label>
                                                    <MultiSelect
                                                        overrideStrings={localization}
                                                        defaultValue={options}
                                                        options={options}
                                                        value={selected}
                                                        onChange={setSelected}
                                                        labelledBy="Select"


                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Цвет клея")}:</label>
                                                    <MultiSelect
                                                        overrideStrings={localization}
                                                        defaultValue={glueOptions}
                                                        options={glueOptions}
                                                        value={selectedGlue}
                                                        onChange={setSelectedGlue}
                                                        labelledBy="Select"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Тип материала")}:</label>
                                                    <MultiSelect
                                                        overrideStrings={localization}
                                                        // defaultValue={myfunk()}
                                                        options={uniqueMatFilters.map((el) => {
                                                            return {
                                                                label: el.name,
                                                                value: el.name
                                                            };
                                                        })}
                                                        value={selectedMat}
                                                        onChange={setSelectedMat}
                                                        labelledBy="Select"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Тип услуг")}:</label>
                                                    <MultiSelect
                                                        overrideStrings={localization}
                                                        defaultValue={myfunk1()}
                                                        options={uniqueServFilters.map((el) => {
                                                            return {
                                                                label: el.name,
                                                                value: el.name
                                                            };
                                                        })}
                                                        value={selectedServ}
                                                        onChange={setSelectedServ}
                                                        labelledBy="Select"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Очередь на сборку")}:</label>
                                                    <select
                                                        value={que}
                                                        onChange={(event) => {
                                                            gaEvents.customEvent('form_order_production', {
                                                                field_focus: 'Очередь на сборку'
                                                            })
                                                            setQue(event.target.value)
                                                        }}
                                                        className="form-control"
                                                    >

                                                        <option value={Number(-1)}>{props.getT("Все")}</option>
                                                        <option
                                                            value={Number(1)}>{props.getT("Есть очередь на сборку")}</option>
                                                        <option value={Number(0)}>{props.getT("Без очереди")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-md-3 col-xl-2">
                                                <div className="form-group">
                                                    <label>{props.getT("Производственное задание")}:</label>
                                                    <select
                                                        value={prod}
                                                        onChange={(e) => {
                                                            gaEvents.customEvent('form_order_production', {
                                                                field_focus: 'Производственное задание'
                                                            })
                                                            handleSetProd(e)
                                                        }}
                                                        className="form-control"
                                                    >
                                                        <option value={Number(0)}>{props.getT("Все")}</option>
                                                        <option value="1">
                                                            {props.getT("Входит в производственное задание")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <section className="content">


                <div className="container-fluid">

                    {false && (
                        <div className="col-sm-4 position-absolute dwopdown">
                            <div className="form-control d-flex justify-content-between align-items-center">
                                <label>{props.getT("Показывать информацию по паллетам")}</label>
                                <input

                                    type="checkbox"
                                    checked={props.settings[1]?.check}
                                />
                            </div>

                        </div>
                    )}
                    <div className="card card-primary card-outline card-tabs">
                        <div className="card-header p-0 pt-1 border-bottom-0">
                            <ul
                                className="nav nav-tabs production-task-nav-container"
                                role="tablist"
                            >
                                <li className="nav-item" onClick={() => {
                                    gaEvents.customEvent('klik_form', {
                                        production_task_tabs: 'Доступны для производства'
                                    })
                                    props.setActiveTab(1)
                                    if (!props.ordersFor?.length) dispatch(OrdersFor());
                                }}>
                                    <a
                                        className={
                                            "nav-link" + (props.activeTab === 1 ? " active" : "")
                                        }
                                    >
                                        {props.getT(`Доступны для производства`)}
                                        {`(${(Number(okCount) !== Number(props?.counts?.production_3) && !!okCount) ?
                                            `${okCount}/${props?.counts?.production_3}` :
                                            okCount})`}
                                    </a>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    gaEvents.customEvent('klik_form', {
                                        production_task_tabs: 'Недоступны для производства'
                                    })
                                    props.setActiveTab(5)
                                }}>
                                    <a
                                        className={
                                            "nav-link" + (props.activeTab === 5 ? " active" : "")
                                        }
                                    >
                                        {props.getT(`Недоступны для производства`)}{`(${badCount})`}
                                    </a>
                                </li>

                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            production_task_tabs: 'В производстве'
                                        })
                                        props.setActiveTab(2);
                                        if (!props.ordersIn?.length) dispatch(OrdersIn());
                                    }}
                                >
                                    <a
                                        className={
                                            "nav-link" + (props.activeTab === 2 ? " active" : "")
                                        }
                                    >
                                        {props.getT(`В производстве`)}
                                        {props?.titleTabOrdersIn ? ` (${props?.ordersIn?.length}/${props?.counts?.production_4})` : ` (${props?.counts?.production_4})`}


                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            production_task_tabs: 'Выполнены'
                                        })
                                        props.setActiveTab(3);
                                        if (!props.ordersComplete?.length) dispatch(OrdersComplete());


                                    }}
                                >
                                    <a
                                        className={
                                            "nav-link" + (props.activeTab === 3 ? " active" : "")
                                        }
                                    >
                                        {props.getT(`Выполнены`)}
                                        {props?.titleTabComplete ? ` (${props.ordersComplete?.length}/${props?.counts?.production_5})` : ` (${props?.counts?.production_5})`}

                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        gaEvents.customEvent('klik_form', {
                                            production_task_tabs: 'Готовы к отгрузке'
                                        })
                                        props.setActiveTab(4);
                                        if (!props.ordersForShipping?.length) dispatch(OrdersForShipping());
                                    }}
                                >
                                    <a
                                        className={
                                            "nav-link" + (props.activeTab === 4 ? " active" : "")
                                        }
                                    >
                                        {props.getT(`Готовы к отгрузке`)}
                                        {props?.titleTabShipping ? ` (${props?.ordersForShipping?.length}/${props?.counts?.production_6})` : ` (${props?.counts?.production_6})`}

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body card-body-production">
                            <div className="tab-content" id="custom-tabs-three-tabContent">
                                <div
                                    className={
                                        "tab-pane fade" +
                                        (props.activeTab === 1 ? " show active" : "")
                                    }
                                >
                                    {props.activeTab === 1 && <div className="card-body p-0">
                                        <div className="table-responsive">

                                            <table className="table m-0 table-striped table-hover">
                                                <THeader getT={props.getT}/>
                                                <tbody>
                                                {data
                                                    .filter(
                                                        (el) =>
                                                            el.client_DB.client_DB_permit === 1 &&
                                                            el.goods_order_without_part === 0 &&
                                                            el.mat.some((mat) =>
                                                                selectedMatrialsValues.includes(mat.name)
                                                            ) &&
                                                            el.serv.some((serv) =>
                                                                selectedServicesValues.includes(serv.name)
                                                            ) &&
                                                            el.glue_type.some((glue) =>
                                                                selectedGlueValue.includes(glue.type_info)
                                                            ) &&
                                                            el.id.includes(searchID) &&
                                                            el.code.includes(searchCode) &&
                                                            (el.edge_type[0] === query[0] ||
                                                                el.edge_type[0] === query[1] ||
                                                                el.edge_type[0] === query[2] ||
                                                                el.edge_type[0] === query[3]) &&
                                                            el.client.name
                                                                .toLowerCase()
                                                                .includes(searchClient) &&
                                                            el.place_in_order_to_pack >= que &&
                                                            el.parent > prod &&
                                                            (!date[0].from ||
                                                                el.confirmed_date > date[0].from) &&
                                                            (!date[0].to ||
                                                                el.confirmed_date < date[0].to) &&
                                                            (!date_production[0].from ||
                                                                el.plan_of_production >
                                                                date_production[0].from) &&
                                                            (!date_production[0].to ||
                                                                el.plan_of_production < date_production[0].to)
                                                    )
                                                    .map((e) => {
                                                        const orderXncProblem = ordersXncStatusGroup?.find(item => Number(item._id) === Number(e.id) && ['error'].includes(item?.xnc?.status))
                                                        return (<>

                                                                {(e.production_stage_number == que && (que == 0)) ? (
                                                                    <React.Fragment key={`tr-${e.id}`}>
                                                                        <tr key={`top_${e.id}`}>
                                                                            <RowTextId getT={props.getT} e={e}
                                                                                       InputCheck={
                                                                                           <input
                                                                                               type="checkbox"
                                                                                               name={e.id}
                                                                                               checked={e.isChecked}
                                                                                               disabled={checkProductionTaskDisabled(e.production_task)}
                                                                                               onChange={(event) => {
                                                                                                   props.setCheckAC(
                                                                                                       e,
                                                                                                       event.currentTarget.checked
                                                                                                   );
                                                                                               }}
                                                                                           />
                                                                                       }/>

                                                                            <td data-label={props.getT("Название")}>
                                                                                {e.order_out_of_date === 1 ? (
                                                                                    <div title={e.name}
                                                                                         className={'text_line'}
                                                                                         style={{color: "red"}}>
                                                                                        {/*<span >*/}
                                                                                        {e.name}
                                                                                        {/*<br/>*/}
                                                                                        {/*{props.getT("Заказ просрочен")}*/}
                                                                                        {/*</span>*/}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div title={e.name}
                                                                                         className={'text_line'}>{e.name}</div>
                                                                                )}
                                                                            </td>
                                                                            <td data-label={props.getT("Точка производства")}>
                                                                                {props.getT(e.adress.production)}
                                                                            </td>
                                                                            <td data-label={props.getT("Дата подтверждения")}
                                                                                title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                                {transformTimeAndDate(e.confirmed_date)?.date}
                                                                            </td>
                                                                            <RowDateOfManufacture getT={props.getT}
                                                                                                  e={e}/>

                                                                            <td data-label={props.getT("Клиент")}>
                                                                                <Link target="_blank"
                                                                                      to={"/clients/detail/" + e.client.id}
                                                                                >
                                                                                    {e.client.name}
                                                                                </Link>
                                                                                <ButtonManualMode
                                                                                    isManualMode={isManualMode}
                                                                                    onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                    isOpen={ids_orders.includes(Number(e.id))}/>
                                                                            </td>

                                                                        </tr>

                                                                        {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                            <tr key={`bottom_${e.id}`}>
                                                                                <td
                                                                                    style={{backgroundColor: "white"}}
                                                                                    colSpan="6"
                                                                                >
                                                                                    {props.settings[2]?.check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start"
                                                                                                }}
                                                                                            >
                                                                                                <h6>{props.getT("Материалы")}:</h6>
                                                                                                {e.mat.length > 0 &&
                                                                                                    e.mat.map((material) => {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {material.name} -{" "}
                                                                                                                {material.count.toFixed(2)}
                                                                                                                {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                                    <span>({material.sheet} {" "} {props.getT("листов")})</span> : null},
                                                                                                            </h6>
                                                                                                        );
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6>{props.getT("Услуги")}:</h6>
                                                                                                {e.serv &&
                                                                                                    e.serv.map((material) => {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {material.name} -{" "}
                                                                                                                {material.count.toFixed(2)},
                                                                                                            </h6>
                                                                                                        );
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}
                                                                                    {props.settings[0].check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6> {props.getT("Сырье")}: </h6>
                                                                                                {e.timeline &&
                                                                                                    e.timeline.map((el) => {
                                                                                                        if (el.timeline?.length > 0) {
                                                                                                            let arr = el.timeline.filter(
                                                                                                                (e) =>
                                                                                                                    e.operation === "accept"
                                                                                                            );

                                                                                                            let sum = arr.reduce(
                                                                                                                function (
                                                                                                                    accumulator,
                                                                                                                    currentValue
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        accumulator +
                                                                                                                        Number(
                                                                                                                            currentValue.count
                                                                                                                        )
                                                                                                                    );
                                                                                                                },
                                                                                                                0
                                                                                                            );
                                                                                                            if (
                                                                                                                sum === Number(el.count)
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <h6
                                                                                                                        style={{
                                                                                                                            marginRight: "10px",
                                                                                                                            marginLeft: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]} {" "}
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                marginLeft: "10px",
                                                                                                                                marginBottom: "10px"
                                                                                                                            }}
                                                                                                                        >
                                                          -{props.getT("Сырье выдано")},
                                                        </span>
                                                                                                                    </h6>
                                                                                                                );
                                                                                                            }
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft: "10px",
                                                                                                                            marginBottom: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                        - {props.getT("Сырье не выданно")},
                                                      </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}
                                                                                    {props.settings[1].check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6> {props.getT("Место Хранения")}: </h6>
                                                                                                {e.pallete.map((pallet) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{marginLeft: "10px"}}
                                                                                                        >
                                                                                                            {pallet.name} -{" "}
                                                                                                            {pallet.pallete}
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}

                                                                                    {props.settings[3].check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6>
                                                                                                    {props.getT("Производственное задание")} №:
                                                                                                </h6>
                                                                                                {e.parent > 1 ? (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {e.parent}
                                                                                                    </h6>
                                                                                                ) : (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >{props.getT("Заказ не" +
                                                                                                        " включен в")}
                                                                                                        <br/>
                                                                                                        {props.getT("производственное" +
                                                                                                            " задание")}
                                                                                                    </h6>
                                                                                                )}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}

                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start"
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <h6 style={{color: "red"}}>
                                                                                            {e.client_DB.client_DB_permit_text}
                                                                                        </h6>
                                                                                    </div>
                                                                                </td>

                                                                                <td data-label={props.getT("Действия")}>

                                                                                    <div className="actions-container">
                                                                                        {canEdit ? (
                                                                                            props.getT("Недостаточно прав")
                                                                                        ) : (
                                                                                            <div
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 4,
                                                                                                        from_status: e?.status_code,
                                                                                                        to_status: 4,
                                                                                                    })
                                                                                                }
                                                                                            >

                                                                                                <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                                <small>{props.getT("В производство")}</small>
                                                                                            </div>
                                                                                        )}

                                                                                        {e.id &&
                                                                                        e.programm_make &&
                                                                                        e.programm_make.length > 0 ? (
                                                                                            <div
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        btn_programm_make: 'Генерация програм'
                                                                                                    })
                                                                                                    props.toggleViewProgramm(
                                                                                                        true,
                                                                                                        e.programm_make
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-fan"></i>
                                                                                                <small>{props.getT("Генерация програм")}</small>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        {canEdit ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <div
                                                                                                className={
                                                                                                    "production-task-action text-danger d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        order_change_status: 'change status'
                                                                                                    })
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 2,
                                                                                                        from_status: findByName(e?.status)?.id,
                                                                                                        to_status: 2,
                                                                                                    })
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-arrow-alt-circle-left"></i>{" "}
                                                                                                <small>
                                                                                                    {props.getT("Отменить готовность")}
                                                                                                </small>
                                                                                            </div>
                                                                                        )}

                                                                                        <div
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                        >
                                                                                            <Link
                                                                                                to={"/orders/detail/" + e.id}
                                                                                                target={"_blank"}
                                                                                            >
                                                                                            <span
                                                                                                className={
                                                                                                    "d-flex align-center mr-1 production_task_item_open"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        click_link: 'open order detail',
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                              <i className="far fa-eye"> </i>{" "}
                                                                                                <small>{props.getT("Просмотр")}</small>
                                                                                            </span>

                                                                                            </Link>
                                                                                        </div>
                                                                                        {e.hasOwnProperty('payment_status') &&
                                                                                            <span className={
                                                                                                "d-flex align-center mr-1 production_task_item_open"
                                                                                            }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>}
                                                                    </React.Fragment>) : ""}


                                                                {(-1 == que) && <>
                                                                    <tr key={uuid()}>

                                                                        <RowTextId getT={props.getT} e={e} InputCheck={
                                                                            <InputChecked name={e.id}
                                                                                          checked={e.isChecked || transfer_group_order.ids.includes(e.id)}
                                                                                          disabled={checkProductionTaskDisabled(
                                                                                              e.production_task
                                                                                          )}
                                                                                          onChange={(event) => {
                                                                                              props.setCheckAC(
                                                                                                  e,
                                                                                                  event.currentTarget.checked
                                                                                              );
                                                                                              onChangeTransferOrder(e.id)
                                                                                          }}
                                                                            />
                                                                        }/>


                                                                        <td data-label={props.getT("Название")}>
                                                                            {e.order_out_of_date === 1 ? (
                                                                                <div title={e.name}
                                                                                     className={'text_line'}
                                                                                     style={{color: "red"}}>
                                                                                    {/*<span style={{color: "red"}}>*/}
                                                                                    {e.name}
                                                                                    {/*<br/>*/}
                                                                                    {/*{props.getT("Заказ просрочен")}*/}
                                                                                    {/*</span>*/}
                                                                                </div>
                                                                            ) : (
                                                                                <div title={e.name}
                                                                                     className={'text_line'}>{e.name}</div>
                                                                            )}
                                                                        </td>
                                                                        <td data-label={props.getT("Точка производства")}>
                                                                            {e.adress.production}
                                                                        </td>
                                                                        <td data-label={props.getT("Дата подтверждения")}
                                                                            title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                            {transformTimeAndDate(e.confirmed_date)?.date}
                                                                        </td>
                                                                        <RowDateOfManufacture getT={props.getT} e={e}/>

                                                                        <ClientItem getT={props.getT} order={e}
                                                                                    clients={props.clients}>


                                                                        </ClientItem>
                                                                        <td data-label={props.getT("Действия")}>
                                                                            <div className="d-flex">
                                                                                <div
                                                                                    className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                    {canEdit ? (
                                                                                        props.getT("Недостаточно прав")
                                                                                    ) : (
                                                                                        <div
                                                                                            title={props.getT("В производство")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 4,
                                                                                                    from_status: e.status_code,
                                                                                                    to_status: 4,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                        </div>
                                                                                    )}

                                                                                    {e.id &&
                                                                                    e.programm_make &&
                                                                                    e.programm_make.length > 0 ? (
                                                                                        <div
                                                                                            title={props.getT("Генерация програм")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.toggleViewProgramm(
                                                                                                    true,
                                                                                                    e.programm_make
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-fan"></i>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {canEdit ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <div
                                                                                            title={props.getT("Отменить готовность")}
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 2,
                                                                                                    from_status: e.status_code,
                                                                                                    to_status: 4,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-left"></i>{" "}

                                                                                        </div>
                                                                                    )}

                                                                                    <div title={props.getT("Просмотр")}
                                                                                         className={
                                                                                             "production-task-action text-danger d-flex align-center mr-1"
                                                                                         }
                                                                                    >
                                                                                        <Link
                                                                                            to={"/orders/detail/" + e.id}
                                                                                            target={"_blank"}
                                                                                        >
                                            <span
                                                className={
                                                    "d-flex align-center mr-1 production_task_item_open"
                                                }
                                                onClick={() => {
                                                    gaEvents.customEvent('klik_form', {
                                                        click_link: 'open order detail',
                                                    })
                                                }}
                                            >
                                              <i className="far fa-eye"> </i>{" "}

                                            </span>
                                                                                        </Link>
                                                                                    </div>
                                                                                    {e.hasOwnProperty('payment_status') &&
                                                                                        <span className={
                                                                                            "d-flex align-center mr-1 production_task_item_open"
                                                                                        }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                </div>
                                                                                <ButtonManualMode
                                                                                    isManualMode={isManualMode}
                                                                                    onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                    isOpen={ids_orders.includes(Number(e.id))}/>
                                                                                <OrderXncAction
                                                                                    orderXncProblem={orderXncProblem}
                                                                                    getT={props.getT}/>
                                                                                <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                    {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                        <tr>
                                                                            <td
                                                                                style={{backgroundColor: "white"}}
                                                                                colSpan="7"
                                                                            >
                                                                                {props.settings[2]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Материалы")}:</h6>
                                                                                            {e.mat.length > 0 &&
                                                                                                e.mat.map((material) => {

                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(2)}
                                                                                                            {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                                <span>({material.sheet} {" "} {props.getT("листов")})</span> : null},

                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Услуги")}:</h6>
                                                                                            {e.serv &&
                                                                                                e.serv.map((material) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(2)} ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props?.settings?.[0]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Сырье")}: </h6>
                                                                                            {e.timeline &&
                                                                                                e.timeline.map((el) => {
                                                                                                    if (el.timeline?.length > 0) {
                                                                                                        let arr = el.timeline.filter(
                                                                                                            (e) =>
                                                                                                                e.operation === "accept"
                                                                                                        );

                                                                                                        let sum = arr.reduce(
                                                                                                            function (
                                                                                                                accumulator,
                                                                                                                currentValue
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    accumulator +
                                                                                                                    Number(
                                                                                                                        currentValue.count
                                                                                                                    )
                                                                                                                );
                                                                                                            },
                                                                                                            0
                                                                                                        );
                                                                                                        if (
                                                                                                            sum === Number(el.count)
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                    {" "}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft: "10px",
                                                                                                                            marginBottom: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                          -{props.getT("Сырье выдано")},
                                                        </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginRight: "10px",
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        marginLeft: "10px",
                                                                                                                        marginBottom: "10px"
                                                                                                                    }}
                                                                                                                >
                                                       -{props.getT("Сырье не выданно")},
                                                      </span>
                                                                                                            </h6>
                                                                                                        );
                                                                                                    }
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings?.[1]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Место" +
                                                                                                " Хранения")}:</h6>
                                                                                            {e.pallete.map((pallet) => {
                                                                                                return (
                                                                                                    <h6
                                                                                                        style={{marginLeft: "10px"}}
                                                                                                    >
                                                                                                        {pallet.name} -{" "}
                                                                                                        {pallet.pallete}
                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}

                                                                                {props.settings?.[3]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>
                                                                                                {props.getT("Производственное задание ")} №:
                                                                                            </h6>
                                                                                            {e.parent > 1 ? (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {e.parent}
                                                                                                </h6>
                                                                                            ) : (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {props.getT("Заказ" +
                                                                                                        " не включен в")}
                                                                                                    <br/>
                                                                                                    {props.getT("производственное" +
                                                                                                        " задание")}
                                                                                                </h6>
                                                                                            )}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}

                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-start"
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    <h6 style={{color: "red"}}>
                                                                                        {e.client_DB.client_DB_permit_text}
                                                                                    </h6>
                                                                                </div>
                                                                            </td>


                                                                        </tr>}
                                                                </>}
                                                                {((1 == que) && e.production_stage_number >= 1) &&
                                                                    <React.Fragment key={`tr-${e.id}`}>
                                                                        <tr key={`top_${e.id}`}>

                                                                            <RowTextId getT={props.getT} e={e}
                                                                                       InputCheck={
                                                                                           <input
                                                                                               type="checkbox"
                                                                                               name={e.id}
                                                                                               checked={e.isChecked}
                                                                                               disabled={checkProductionTaskDisabled(
                                                                                                   e.production_task
                                                                                               )}
                                                                                               onChange={(event) => {
                                                                                                   props.setCheckAC(
                                                                                                       e,
                                                                                                       event.currentTarget.checked
                                                                                                   );
                                                                                               }}
                                                                                           />
                                                                                       }/>


                                                                            <td data-label={props.getT("Название")}>
                                                                                {e.order_out_of_date === 1 ? (
                                                                                    <div title={e.name}
                                                                                         style={{color: "red"}}
                                                                                         className={'text_line'}>
                                                                                        {e.name}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div title={e.name}
                                                                                         className={'text_line'}>{e.name}</div>
                                                                                )}
                                                                            </td>
                                                                            <td data-label={props.getT("Точка производства")}>
                                                                                {e.adress.production}
                                                                            </td>
                                                                            <td data-label={props.getT("Дата подтверждения")}
                                                                                title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                                {transformTimeAndDate(e.confirmed_date)?.date}
                                                                            </td>
                                                                            <RowDateOfManufacture getT={props.getT}
                                                                                                  e={e}/>

                                                                            <td data-label={props.getT("Клиент")}>
                                                                                <Link target="_blank"
                                                                                      to={"/clients/detail/" + e.client.id}
                                                                                >
                                                                                    {e.client.name}
                                                                                </Link>


                                                                            </td>
                                                                            <td data-label={props.getT("Действия")}>
                                                                                <div className="d-flex">
                                                                                    <div
                                                                                        className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                        {canEdit ? (
                                                                                            props.getT("Недостаточно прав")
                                                                                        ) : (
                                                                                            <div
                                                                                                title={props.getT("В производство")}
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 4,
                                                                                                        from_status: e.status_code,
                                                                                                        to_status: 4,
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                            </div>
                                                                                        )}

                                                                                        {e.id &&
                                                                                        e.programm_make &&
                                                                                        e.programm_make.length > 0 ? (
                                                                                            <div
                                                                                                title={props.getT("Генерация програм")}
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    props.toggleViewProgramm(
                                                                                                        true,
                                                                                                        e.programm_make
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-fan"></i>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        {canEdit ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <div
                                                                                                title={props.getT("Отменить готовность")}
                                                                                                className={
                                                                                                    "production-task-action text-danger d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 2,
                                                                                                        from_status: e.status_code,
                                                                                                        to_status: 2,
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-arrow-alt-circle-left"></i>{" "}
                                                                                            </div>
                                                                                        )}

                                                                                        <div
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                        >
                                                                                            <Link
                                                                                                to={"/orders/detail/" + e.id}
                                                                                                target={"_blank"}
                                                                                            >
                                            <span
                                                title={props.getT("Просмотр")}
                                                className={
                                                    "d-flex align-center mr-1 production_task_item_open"
                                                }
                                                onClick={() => {
                                                    gaEvents.customEvent('klik_form', {
                                                        click_link: 'open order detail',
                                                    })
                                                }}
                                            >
                                              <i className="far fa-eye"> </i>{" "}
                                            </span>
                                                                                            </Link>
                                                                                        </div>
                                                                                        {e.hasOwnProperty('payment_status') &&
                                                                                            <span className={
                                                                                                "d-flex align-center mr-1 production_task_item_open"
                                                                                            }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                    </div>
                                                                                    <ButtonManualMode
                                                                                        isManualMode={isManualMode}
                                                                                        onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                        isOpen={ids_orders.includes(Number(e.id))}/>

                                                                                    <OrderXncAction
                                                                                        orderXncProblem={orderXncProblem}
                                                                                        getT={props.getT}/>
                                                                                    <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                            <tr key={`bottom_${e.id}`}>
                                                                                <td
                                                                                    style={{backgroundColor: "white"}}
                                                                                    colSpan="7"
                                                                                >
                                                                                    {props.settings?.[2]?.check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start"
                                                                                                }}
                                                                                            >
                                                                                                <h6>{props.getT("Материалы")}:</h6>
                                                                                                {e.mat.length > 0 &&
                                                                                                    e.mat.map((material) => {

                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {material.name} -{" "}
                                                                                                                {material.count.toFixed(2)}
                                                                                                                {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                                    <span>({material.sheet} {" "} {props.getT("листов")})</span> : null},

                                                                                                            </h6>
                                                                                                        );
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6>{props.getT("Услуги")}:</h6>
                                                                                                {e.serv &&
                                                                                                    e.serv.map((material) => {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {material.name} -{" "}
                                                                                                                {material.count.toFixed(2)} ,
                                                                                                            </h6>
                                                                                                        );
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}
                                                                                    {props.settings?.[0]?.check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6> {props.getT("Сырье")}: </h6>
                                                                                                {e.timeline &&
                                                                                                    e.timeline.map((el) => {
                                                                                                        if (el.timeline?.length > 0) {
                                                                                                            let arr = el.timeline.filter(
                                                                                                                (e) =>
                                                                                                                    e.operation === "accept"
                                                                                                            );

                                                                                                            let sum = arr.reduce(
                                                                                                                function (
                                                                                                                    accumulator,
                                                                                                                    currentValue
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        accumulator +
                                                                                                                        Number(
                                                                                                                            currentValue.count
                                                                                                                        )
                                                                                                                    );
                                                                                                                },
                                                                                                                0
                                                                                                            );
                                                                                                            if (
                                                                                                                sum === Number(el.count)
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <h6
                                                                                                                        style={{
                                                                                                                            marginRight: "10px",
                                                                                                                            marginLeft: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}{" "}
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                marginLeft: "10px",
                                                                                                                                marginBottom: "10px"
                                                                                                                            }}
                                                                                                                        >
                                                          -{props.getT("Сырье выдано")},
                                                        </span>
                                                                                                                    </h6>
                                                                                                                );
                                                                                                            }
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft: "10px",
                                                                                                                            marginBottom: "10px"
                                                                                                                        }}
                                                                                                                    >
                                                        -{props.getT("Сырье не выданно")},
                                                      </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}
                                                                                    {props.settings?.[1]?.check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6> {props.getT("Место" +
                                                                                                    " Хранения")}: </h6>
                                                                                                {e.pallete.map((pallet) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{marginLeft: "10px"}}
                                                                                                        >
                                                                                                            {pallet.name} -{" "}
                                                                                                            {pallet.pallete}
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}

                                                                                    {props.settings?.[3]?.check && (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "flex-start",
                                                                                                    flexWrap: "wrap"
                                                                                                }}
                                                                                            >
                                                                                                <h6>
                                                                                                    {props.getT("Производственное задание")}№:
                                                                                                </h6>
                                                                                                {e.parent > 1 ? (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {e.parent}
                                                                                                    </h6>
                                                                                                ) : (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {props.getT("Заказ не включен в производственное задание")}
                                                                                                    </h6>
                                                                                                )}
                                                                                            </div>
                                                                                            <hr/>
                                                                                        </>
                                                                                    )}

                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start"
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <h6 style={{color: "red"}}>
                                                                                            {e.client_DB.client_DB_permit_text}
                                                                                        </h6>
                                                                                    </div>
                                                                                </td>


                                                                            </tr>}
                                                                    </React.Fragment>}
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className="card-footer clearfix"></div>
                                </div>
                                <div
                                    className={
                                        "tab-pane fade" +
                                        (props.activeTab === 5 ? " show active" : "")
                                    }
                                >
                                    {props.activeTab === 5 && <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-striped table-hover">
                                                <THeader getT={props.getT}/>
                                                <tbody>
                                                {dataBad
                                                    .filter(
                                                        (el) =>
                                                            el.client_DB.client_DB_permit === 0 &&
                                                            el.goods_order_without_part === 0 &&
                                                            el.id.includes(searchID) &&
                                                            (!searchManager ? true : Number(el.manager?.id) === Number(searchManager)) &&
                                                            el.code.includes(searchCode) &&
                                                            el.name.toLowerCase().includes(searchName) &&
                                                            el.client.name
                                                                .toLowerCase()
                                                                .includes(searchClient) &&
                                                            (!date[0].from ||
                                                                el.confirmed_date > date[0].from) &&
                                                            (!date[0].to ||
                                                                el.confirmed_date < date[0].to) &&
                                                            (!date_production[0].from ||
                                                                el.plan_of_production >
                                                                date_production[0].from) &&
                                                            (!date_production[0].to ||
                                                                el.plan_of_production < date_production[0].to)
                                                    )
                                                    .map((e) => {
                                                        const orderXncProblem = ordersXncStatusGroup?.find(item => Number(item._id) === Number(e.id) && ['error'].includes(item?.xnc?.status))
                                                        const stage_number_production =
                                                            e.production_stage_number ? (
                                                                <>
                                                                    <br/>{" "}
                                                                    <small>
                                                                        {props.getT("Очередь на производство")}:
                                                                        {e.production_stage_number}
                                                                    </small>
                                                                </>
                                                            ) : (
                                                                ""
                                                            );
                                                        return (
                                                            <React.Fragment key={`tr-${e.id}`}>
                                                                <tr key={`top_${e.id}`}>

                                                                    <RowTextId getT={props.getT} e={e}
                                                                               InputCheck={<InputChecked name={e.id}
                                                                                                         checked={transfer_group_order.ids.includes(e.id)}
                                                                                                         onChange={() => {
                                                                                                             onChangeTransferOrder(e.id)
                                                                                                         }}
                                                                               />}
                                                                    />
                                                                    <td data-label={props.getT("Название")}>
                                                                        {e.order_out_of_date === 1 ? (
                                                                            <div title={e.name} className={'text_line'}
                                                                                 style={{color: "red"}}>
                                                                                {/*<span style={{color: "red"}}>*/}
                                                                                {e.name}
                                                                                {/*<br/>*/}
                                                                                {/*{props.getT("Заказ Просрочен")}*/}
                                                                                {/*</span>*/}
                                                                            </div>
                                                                        ) : (
                                                                            <div title={e.name}
                                                                                 className={'text_line'}>{e.name}</div>
                                                                        )}
                                                                    </td>

                                                                    <td data-label={props.getT("Точка производства")}>
                                                                        {e.adress.production}
                                                                    </td>
                                                                    <td data-label={props.getT("Дата подтверждения")}
                                                                        title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                        {transformTimeAndDate(e.confirmed_date)?.date}
                                                                    </td>
                                                                    <RowDateOfManufacture getT={props.getT} e={e}/>
                                                                    <ClientItem getT={props.getT} order={e}
                                                                                clients={props.clients}>


                                                                    </ClientItem>
                                                                    <td data-label={props.getT("Действия")}>
                                                                        <div className="d-flex">
                                                                            <OrderXncAction getT={props.getT}
                                                                                            orderXncProblem={orderXncProblem}/>
                                                                            <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>

                                                                            <div
                                                                                className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                {canEdit ? (
                                                                                    props.getT("Недостаточно прав")
                                                                                ) : (
                                                                                    <div
                                                                                        title={props.getT("В производство")}
                                                                                        className={
                                                                                            "production-task-action text-success d-flex align-center mr-1"
                                                                                        }
                                                                                        onClick={() =>
                                                                                            props.updateOrder(e.id, {
                                                                                                status: 4,
                                                                                                from_status: e.status_code,
                                                                                                to_status: 4,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                    </div>
                                                                                )}

                                                                                {e.id &&
                                                                                e.programm_make &&
                                                                                e.programm_make.length > 0 ? (
                                                                                    <div
                                                                                        title={props.getT("Генерация програм")}
                                                                                        className={
                                                                                            "production-task-action text-success d-flex align-center mr-1"
                                                                                        }
                                                                                        onClick={() =>
                                                                                            props.toggleViewProgramm(
                                                                                                true,
                                                                                                e.programm_make
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="fas fa-fan"></i>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                                {canEdit ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <div
                                                                                        title={props.getT("Отменить готовность")}
                                                                                        className={
                                                                                            "production-task-action text-danger d-flex align-center mr-1"
                                                                                        }
                                                                                        onClick={() =>
                                                                                            props.updateOrder(e.id, {
                                                                                                status: 2,
                                                                                                from_status: e.status_code,
                                                                                                to_status: 2,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <i className="fas fa-arrow-alt-circle-left"></i>{" "}
                                                                                    </div>
                                                                                )}

                                                                                <div
                                                                                    className={
                                                                                        "production-task-action text-danger d-flex align-center mr-1"
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to={"/orders/detail/" + e.id}
                                                                                        target={"_blank"}
                                                                                    >
                                            <span title={props.getT("Просмотр")}
                                                  className={
                                                      "d-flex align-center mr-1 production_task_item_open"
                                                  }
                                                  onClick={() => {
                                                      gaEvents.customEvent('klik_form', {
                                                          click_link: 'open order detail',
                                                      })
                                                  }}
                                            >
                                              <i className="far fa-eye"> </i>{" "}
                                            </span>
                                                                                    </Link>
                                                                                </div>
                                                                                {e.hasOwnProperty('payment_status') &&
                                                                                    <span className={
                                                                                        "d-flex align-center mr-1 production_task_item_open"
                                                                                    }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                            </div>
                                                                            <ButtonManualMode
                                                                                isManualMode={isManualMode}
                                                                                onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                isOpen={ids_orders.includes(Number(e.id))}/>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                    <tr key={`bottom_${e.id}`}>
                                                                        <td
                                                                            style={{backgroundColor: "white"}}
                                                                            colSpan="7"
                                                                        >
                                                                            {props.settings?.[2]?.check && (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start"
                                                                                        }}
                                                                                    >
                                                                                        <h6>{props.getT("Материалы")}:</h6>

                                                                                        {e.mat.length > 0 &&
                                                                                            e.mat.map((material) => {

                                                                                                return (
                                                                                                    <h6
                                                                                                        key={material.name}
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {material.name} -{" "}
                                                                                                        {material.count.toFixed(2)}
                                                                                                        {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                            <span>({material.sheet} {" "} {props.getT("листов")})</span> : null},

                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                    </div>
                                                                                    <hr/>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start",
                                                                                            flexWrap: "wrap"
                                                                                        }}
                                                                                    >
                                                                                        <h6>Услуги :</h6>
                                                                                        {e.serv &&
                                                                                            e.serv.map((material) => {
                                                                                                return (
                                                                                                    <h6
                                                                                                        key={material.name}
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {material.name} -{" "}
                                                                                                        {material.count.toFixed(2)} ,
                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                    </div>
                                                                                    <hr/>
                                                                                </>
                                                                            )}
                                                                            {props.settings?.[0]?.check && (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start",
                                                                                            flexWrap: "wrap"
                                                                                        }}
                                                                                    >
                                                                                        <h6> {props.getT("Сырье")}: </h6>
                                                                                        {e.timeline &&
                                                                                            e.timeline.map((el) => {
                                                                                                if (el.timeline?.length > 0) {
                                                                                                    let arr = el.timeline.filter(
                                                                                                        (e) =>
                                                                                                            e.operation === "accept"
                                                                                                    );

                                                                                                    let sum = arr.reduce(
                                                                                                        function (
                                                                                                            accumulator,
                                                                                                            currentValue
                                                                                                        ) {
                                                                                                            return (
                                                                                                                accumulator +
                                                                                                                Number(
                                                                                                                    currentValue.count
                                                                                                                )
                                                                                                            );
                                                                                                        },
                                                                                                        0
                                                                                                    );
                                                                                                    if (
                                                                                                        sum === Number(el.count)
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginRight: "10px",
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}{" "}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        marginLeft: "10px",
                                                                                                                        marginBottom: "10px"
                                                                                                                    }}
                                                                                                                >
                                                        - {props.getT("Сырье выдано")}, {" "}
                                                        </span>
                                                                                                            </h6>
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginRight: "10px",
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    marginLeft: "10px",
                                                                                                                    marginBottom: "10px"
                                                                                                                }}
                                                                                                            >
                                                        -{props.getT("Сырье не выданно")},
                                                      </span>
                                                                                                        </h6>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                    </div>
                                                                                    <hr/>
                                                                                </>
                                                                            )}
                                                                            {props.settings[1].check && (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start",
                                                                                            flexWrap: "wrap"
                                                                                        }}
                                                                                    >
                                                                                        <h6> {props.getT("Место Хранения")}: </h6>
                                                                                        {e.pallete.map((pallet) => {
                                                                                            return (
                                                                                                <h6
                                                                                                    key={pallet.name}
                                                                                                    style={{marginLeft: "10px"}}
                                                                                                >
                                                                                                    {pallet.name} -{" "}
                                                                                                    {pallet.pallete}
                                                                                                </h6>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                    <hr/>
                                                                                </>
                                                                            )}
                                                                            {props.settings[3].check && (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-start",
                                                                                            flexWrap: "wrap"
                                                                                        }}
                                                                                    >
                                                                                        <h6>{props.getT("Производственное задание")}№:</h6>
                                                                                        {e.parent > 1 ? (
                                                                                            <h6
                                                                                                style={{
                                                                                                    marginLeft: "10px"
                                                                                                }}
                                                                                            >
                                                                                                {e.parent}
                                                                                            </h6>
                                                                                        ) : (
                                                                                            <h6
                                                                                                style={{
                                                                                                    marginLeft: "10px"
                                                                                                }}
                                                                                            >
                                                                                                {props.getT("Задание не включено в производственное задание")}
                                                                                            </h6>
                                                                                        )}
                                                                                    </div>
                                                                                    <hr/>
                                                                                </>
                                                                            )}
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                <h6 style={{color: "red"}}>
                                                                                    {e.client_DB.client_DB_permit_text}
                                                                                </h6>
                                                                            </div>
                                                                        </td>


                                                                    </tr>}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className="card-footer clearfix"></div>
                                </div>
                                <div
                                    className={
                                        "tab-pane fade" +
                                        (props.activeTab === 2 ? " show active" : "")
                                    }
                                >
                                    {props.activeTab === 2 && <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-striped table-hover">
                                                <THeader getT={props.getT}>
                                                    {!Number(q_salary_usage_wihth_report_button) &&
                                                        <th scope="col">{props.getT("Готовность услуг")}</th>}
                                                </THeader>

                                                <tbody key={`orders_in_${uuid()}`}>
                                                {/*<tr>{uuid()}</tr>*/}

                                                {props.ordersIn &&
                                                    dataIn
                                                        .filter(
                                                            (el) =>
                                                                el.id.includes(searchID) &&
                                                                (!searchManager ? true : Number(el.manager?.id) === Number(searchManager)) &&
                                                                el.goods_order_without_part === 0 &&
                                                                el.code.includes(searchCode) &&
                                                                el.name.toLowerCase().includes(searchName) &&
                                                                el.client.name
                                                                    .toLowerCase()
                                                                    .includes(searchClient) &&
                                                                (!date[0].from ||
                                                                    el.confirmed_date >= date[0].from) &&
                                                                (!date[0].to ||
                                                                    el.confirmed_date <= date[0].to) &&
                                                                (!date_production[0].from ||
                                                                    el.plan_of_production >=
                                                                    date_production[0].from) &&
                                                                (!date_production[0].to ||
                                                                    el.plan_of_production <=
                                                                    date_production[0].to)
                                                        )
                                                        .map((e) => {
                                                            const orderXncProblem = ordersXncStatusGroup?.find(item => Number(item._id) === Number(e.id) && ['error'].includes(item?.xnc?.status))

                                                            return (
                                                                <React.Fragment key={`tr-${e.id}`}>
                                                                    <tr key={`top_${e.id}`}>
                                                                        <RowTextId getT={props.getT} e={e} InputCheck={
                                                                            <InputChecked name={e.id}
                                                                                          checked={transfer_group_order.ids.includes(e.id)}
                                                                                          onChange={() => {
                                                                                              onChangeTransferOrder(e.id)
                                                                                          }}
                                                                            />
                                                                        }/>


                                                                        <td data-label={props.getT("Название")}>
                                                                            {e.order_out_of_date === 1 ? (
                                                                                <div title={e.name}
                                                                                     className={'text_line'}
                                                                                     style={{color: "red"}}>
                                                                                    {/*<span style={{color: "red"}}>*/}
                                                                                    {e.name}
                                                                                    {/*<br/>*/}
                                                                                    {/*{props.getT("Заказ просрочен")}*/}
                                                                                    {/*</span>*/}
                                                                                </div>
                                                                            ) : (
                                                                                <div title={e.name}
                                                                                     className={'text_line'}>{e.name}</div>
                                                                            )}
                                                                        </td>

                                                                        <td data-label={props.getT("Точка производства")}>
                                                                            {e.adress.production}
                                                                        </td>
                                                                        <td data-label={props.getT("Дата подтверждения")}
                                                                            title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                            {transformTimeAndDate(e.confirmed_date)?.date}
                                                                        </td>
                                                                        <RowDateOfManufacture getT={props.getT} e={e}/>
                                                                        {!Number(q_salary_usage_wihth_report_button) &&
                                                                            <td data-label={props.getT("Все")}>
                                                                                <div className="form-group">
                                                                                    <button
                                                                                        className="open-modal"
                                                                                        onClick={() => {
                                                                                            setActiveOrder(e.services_status);
                                                                                            setActiveOrderInId(e.id);
                                                                                        }}
                                                                                    >
                                                                                        {props.getT("Список услуг")}
                                                                                    </button>
                                                                                </div>
                                                                            </td>}
                                                                        <ClientItem getT={props.getT} order={e}
                                                                                    clients={props.clients}>


                                                                        </ClientItem>
                                                                        <td data-label={props.getT("Действие")}>
                                                                            <div className="d-flex">
                                                                                <OrderXncAction getT={props.getT}
                                                                                                orderXncProblem={orderXncProblem}/>
                                                                                <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>

                                                                                <div
                                                                                    className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                    {canEdit ? props.getT("Недостаточно прав") : ""}

                                                                                    {e.id &&
                                                                                    e.programm_make &&
                                                                                    e.programm_make.length > 0 ? (
                                                                                        <div
                                                                                            title={props.getT("Генерация програм")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.toggleViewProgramm(
                                                                                                    true,
                                                                                                    e.programm_make
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-fan"></i>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {canEdit ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <>
                                                                                            {" "}
                                                                                            <div
                                                                                                title={props.getT("выполнен")}
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        order_change_status: 'change status'
                                                                                                    })
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 5,
                                                                                                        from_status: e.status_code,
                                                                                                        to_status: 5,

                                                                                                    })
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                            </div>
                                                                                            <div
                                                                                                title={props.getT("Снять с производства")}
                                                                                                className={
                                                                                                    "production-task-action text-danger d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        order_change_status: 'change status'
                                                                                                    })
                                                                                                    props.updateOrder(e.id, {
                                                                                                        status: 3,
                                                                                                        from_status: e.status_code,
                                                                                                        to_status: 3,
                                                                                                    })
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-arrow-alt-circle-left"></i>{" "}

                                                                                            </div>
                                                                                            <div
                                                                                                title={props.getT("Сообщить о проблеме")}
                                                                                                className={
                                                                                                    "production-task-action text-danger d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    gaEvents.customEvent('klik_form', {
                                                                                                        btn_set_problem: 'Сообщить о проблеме'
                                                                                                    })
                                                                                                    props.toggleSetProblem(
                                                                                                        true,
                                                                                                        e.id
                                                                                                    )
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-exclamation-triangle"></i>{" "}

                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    <div
                                                                                        className={
                                                                                            "production-task-action text-danger d-flex align-center mr-1"
                                                                                        }
                                                                                    >
                                                                                        <Link
                                                                                            to={"/orders/detail/" + e.id}
                                                                                            target={"_blank"}
                                                                                        >
                                              <span title={props.getT("Просмотр")}
                                                    className={
                                                        "d-flex align-center mr-1 production_task_item_open"
                                                    }
                                                    onClick={() => {
                                                        gaEvents.customEvent('klik_form', {
                                                            click_link: 'open order detail',
                                                        })
                                                    }}
                                              >
                                                <i className="far fa-eye"> </i>{" "}
                                              </span>
                                                                                        </Link>
                                                                                    </div>
                                                                                    {e.hasOwnProperty('payment_status') &&
                                                                                        <span className={
                                                                                            "d-flex align-center mr-1 production_task_item_open"
                                                                                        }>

                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                </div>
                                                                                <ButtonManualMode
                                                                                    isManualMode={isManualMode}
                                                                                    onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                    isOpen={ids_orders.includes(Number(e.id))}/>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                    {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                        <tr key={`bottom_${e.id}`}>
                                                                            <td
                                                                                style={{backgroundColor: "white"}}
                                                                                colSpan="8"
                                                                            >
                                                                                {props.settings[2]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Материалы")}:</h6>
                                                                                            {e.mat.length > 0 &&
                                                                                                e.mat.map((material) => {

                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                                <span>({material.sheet} {" "} {props.getT("листов")})</span> : null},

                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Услуги")}:</h6>
                                                                                            {e.serv &&
                                                                                                e.serv.map((material) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}

                                                                                                            ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[0].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Сырье")}: </h6>
                                                                                            {e.timeline &&
                                                                                                e.timeline.map((el) => {
                                                                                                    if (el.timeline?.length > 0) {
                                                                                                        let arr =
                                                                                                            el.timeline.filter(
                                                                                                                (e) =>
                                                                                                                    e.operation ===
                                                                                                                    "accept"
                                                                                                            );

                                                                                                        let sum = arr.reduce(
                                                                                                            function (
                                                                                                                accumulator,
                                                                                                                currentValue
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    accumulator +
                                                                                                                    Number(
                                                                                                                        currentValue.count
                                                                                                                    )
                                                                                                                );
                                                                                                            },
                                                                                                            0
                                                                                                        );
                                                                                                        if (
                                                                                                            sum === Number(el.count)
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}{" "}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft:
                                                                                                                                "10px",
                                                                                                                            marginBottom:
                                                                                                                                "10px"
                                                                                                                        }}
                                                                                                                    >
                                                            -{props.getT("Сырье выдано")},{" "}
                                                          </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginRight: "10px",
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        marginLeft: "10px",
                                                                                                                        marginBottom: "10px"
                                                                                                                    }}
                                                                                                                >
                                                          -{props.getT("Сырье не выданно")},
                                                        </span>
                                                                                                            </h6>
                                                                                                        );
                                                                                                    }
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}

                                                                                {props.settings[1].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Место Хранения")}: </h6>
                                                                                            {e.pallete.map((pallet) => {
                                                                                                return (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {pallet.name} -{" "}
                                                                                                        {pallet.pallete}
                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[3].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>
                                                                                                {props.getT("Производственное задание")} №:
                                                                                            </h6>
                                                                                            {e.parent > 1 ? (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {e.parent}
                                                                                                </h6>
                                                                                            ) : (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {props.getT("Задание не" +
                                                                                                        " включено в ")}
                                                                                                    <br/>
                                                                                                    {props.getT("производственное задание")}
                                                                                                </h6>
                                                                                            )}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                            </td>

                                                                        </tr>}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className="card-footer clearfix">
                                    </div>
                                </div>
                                <div
                                    className={
                                        "tab-pane fade" +
                                        (props.activeTab === 3 ? " show active" : "")
                                    }
                                >
                                    {props.activeTab === 3 && <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <p style={{textTransform: "uppercase"}}>{props.getT("Выполнены")}</p>
                                            <table className="table m-0 table-striped table-hover">
                                                <THeader getT={props.getT}/>
                                                <tbody>
                                                {props.ordersComplete &&
                                                    props.ordersComplete
                                                        .filter(
                                                            (el) =>
                                                                el.id.includes(searchID) &&
                                                                (!searchManager ? true : Number(el.manager?.id) === Number(searchManager)) &&
                                                                el.code.includes(searchCode) &&
                                                                el.name.toLowerCase().includes(searchName) &&
                                                                el.client.name
                                                                    .toLowerCase()
                                                                    .includes(searchClient) &&
                                                                (!date[0].from ||
                                                                    el.confirmed_date >= date[0].from) &&
                                                                (!date[0].to ||
                                                                    el.confirmed_date <= date[0].to) &&
                                                                (!date_production[0].from ||
                                                                    el.plan_of_production >=
                                                                    date_production[0].from) &&
                                                                (!date_production[0].to ||
                                                                    el.plan_of_production <=
                                                                    date_production[0].to)
                                                        )
                                                        .map((e) => {
                                                            const orderXncProblem = ordersXncStatusGroup?.find(item => Number(item._id) === Number(e.id) && ['error'].includes(item?.xnc?.status))

                                                            return (
                                                                <React.Fragment key={`tr-${e.id}`}>
                                                                    <tr key={`top_${e.id}`}>
                                                                        <RowTextId getT={props.getT} e={e} InputCheck={
                                                                            <InputChecked name={e.id}
                                                                                          checked={transfer_group_order.ids.includes(e.id)}
                                                                                          onChange={(event) => {
                                                                                              onChangeTransferOrder(e.id)
                                                                                          }}
                                                                            />
                                                                        }/>
                                                                        <td data-label={props.getT("Название")}>
                                                                            <div title={e.name} className="text_line">
                                                                                {e.name}
                                                                            </div>
                                                                        </td>
                                                                        <td data-label={props.getT("Точка производства")}>
                                                                            {e.adress.production}
                                                                        </td>
                                                                        <td data-label={props.getT("Дата подтверждения")}
                                                                            title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                            {transformTimeAndDate(e.confirmed_date)?.date}
                                                                        </td>
                                                                        <RowDateOfManufacture getT={props.getT} e={e}/>
                                                                        <ClientItem getT={props.getT} order={e}
                                                                                    clients={props.clients}>

                                                                        </ClientItem>
                                                                        {canEdit ? (

                                                                            <td>
                                                                                <div className="d-flex align-items-center">


                                                                                <div
                                                                                    title={props.getT("Просмотр")}
                                                                                    className={
                                                                                        "production-task-action text-danger d-flex align-center mr-1"
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to={"/orders/detail/" + e.id}
                                                                                        target={"_blank"}
                                                                                    >
                                                                                        <span
                                                                                            className={
                                                                                                "d-flex align-center mr-1 production_task_item_open"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                gaEvents.customEvent('klik_form', {
                                                                                                    click_link: 'open order detail',
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                          <i className="far fa-eye">
                                                                                            {" "}
                                                                                          </i>
                                                                                        </span>
                                                                                    </Link>
                                                                                </div>
                                                                                {props.getT("Недостаточно прав")}

                                                                                </div>
                                                                                </td>
                                                                        ) : (
                                                                            <td data-label={props.getT("Действия")}>
                                                                                <div className="d-flex">
                                                                                    <OrderXncAction getT={props.getT}
                                                                                                    orderXncProblem={orderXncProblem}/>
                                                                                    <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>

                                                                                    <div
                                                                                        className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                        <div
                                                                                            title={props.getT("Вернуть в производство")}
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 4,
                                                                                                    from_status: e.status_code,
                                                                                                    to_status: 4,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-left"></i>{" "}

                                                                                        </div>
                                                                                        {e.id &&
                                                                                        e.programm_make &&
                                                                                        e.programm_make.length > 0 ? (
                                                                                            <div
                                                                                                title={props.getT("Генерация програм")}
                                                                                                className={
                                                                                                    "production-task-action text-success d-flex align-center mr-1"
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    props.toggleViewProgramm(
                                                                                                        true,
                                                                                                        e.programm_make
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="fas fa-fan"></i>
                                                                                            </div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        <div
                                                                                            title={props.getT("Готов к отгрузке")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() =>
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 6,
                                                                                                    from_status: e.status_code,
                                                                                                    to_status: 6,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                        </div>
                                                                                        <div
                                                                                            title={props.getT("Просмотр")}
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                        >
                                                                                            <Link
                                                                                                to={"/orders/detail/" + e.id}
                                                                                                target={"_blank"}
                                                                                            >
                                                <span
                                                    className={
                                                        "d-flex align-center mr-1 production_task_item_open"
                                                    }
                                                    onClick={() => {
                                                        gaEvents.customEvent('klik_form', {
                                                            click_link: 'open order detail',
                                                        })
                                                    }}
                                                >
                                                  <i className="far fa-eye">
                                                    {" "}
                                                  </i>
                                                </span>
                                                                                            </Link>
                                                                                        </div>
                                                                                        {e.hasOwnProperty('payment_status') &&
                                                                                            <span className={
                                                                                                "d-flex align-center mr-1 production_task_item_open"
                                                                                            }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                    </div>
                                                                                    <ButtonManualMode
                                                                                        isManualMode={isManualMode}
                                                                                        onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                        isOpen={ids_orders.includes(Number(e.id))}/>
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                    {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                        <tr key={`bottom_${e.id}`}>
                                                                            <td
                                                                                style={{backgroundColor: "white"}}
                                                                                colSpan="7"
                                                                            >
                                                                                {props.settings[2]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Материалы")}:</h6>
                                                                                            {e.mat.length > 0 &&
                                                                                                e.mat.map((material) => {

                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            {material?.sheet && (Number(material?.sheet) > 0) ?
                                                                                                                <span>({material.sheet} {" "} {props.getT("листов")})</span> : null}
                                                                                                            ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Услуги")}:</h6>
                                                                                            {e.serv &&
                                                                                                e.serv.map((material) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[0].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Сырье")}: </h6>
                                                                                            {e.timeline &&
                                                                                                e.timeline.map((el) => {
                                                                                                    if (el.timeline?.length > 0) {
                                                                                                        let arr =
                                                                                                            el.timeline.filter(
                                                                                                                (e) =>
                                                                                                                    e.operation ===
                                                                                                                    "accept"
                                                                                                            );

                                                                                                        let sum = arr.reduce(
                                                                                                            function (
                                                                                                                accumulator,
                                                                                                                currentValue
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    accumulator +
                                                                                                                    Number(
                                                                                                                        currentValue.count
                                                                                                                    )
                                                                                                                );
                                                                                                            },
                                                                                                            0
                                                                                                        );
                                                                                                        if (
                                                                                                            sum === Number(el.count)
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}{" "}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft:
                                                                                                                                "10px",
                                                                                                                            marginBottom:
                                                                                                                                "10px"
                                                                                                                        }}
                                                                                                                    >
                                                            -{props.getT("Сырье выдано")},{" "}
                                                          </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginRight: "10px",
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        marginLeft: "10px",
                                                                                                                        marginBottom: "10px"
                                                                                                                    }}
                                                                                                                >
                                                          -{props.getT("Сырье не выданно")},
                                                        </span>
                                                                                                            </h6>
                                                                                                        );
                                                                                                    }
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[1].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Место Хранения")}: </h6>
                                                                                            {e.pallete.map((pallet) => {
                                                                                                return (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {pallet.name} -{" "}
                                                                                                        {pallet.pallete}
                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[3].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>
                                                                                                {props.getT("Производственное задание ")}№:
                                                                                            </h6>
                                                                                            {e.parent > 1 ? (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {e.parent}
                                                                                                </h6>
                                                                                            ) : (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >{props.getT("Задание не" +
                                                                                                    " включено")} <br/>
                                                                                                    {props.getT("в производственное задание")}
                                                                                                </h6>
                                                                                            )}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                            </td>

                                                                        </tr>}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className="card-footer clearfix">

                                    </div>
                                </div>
                                <div
                                    className={
                                        "tab-pane fade" +
                                        (props.activeTab === 4 ? " show active" : "")
                                    }
                                >
                                    {props.activeTab === 4 && <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <p style={{textTransform: "uppercase"}}>{props.getT("Готовы к отгрузке")}</p>
                                            <table className="table m-0 table-striped table-hover">
                                                <THeader getT={props.getT}/>
                                                <tbody>
                                                {props.ordersForShipping &&
                                                    props.ordersForShipping
                                                        .filter(
                                                            (el) =>
                                                                el.id.includes(searchID) &&
                                                                el.code.includes(searchCode) &&
                                                                el.name.toLowerCase().includes(searchName) &&
                                                                (!searchManager ? true : Number(el?.manager.id) === Number(searchManager)) &&
                                                                el.client.name
                                                                    .toLowerCase()
                                                                    .includes(searchClient) &&
                                                                (!date[0].from ||
                                                                    el.confirmed_date >= date[0].from) &&
                                                                (!date[0].to ||
                                                                    el.confirmed_date <= date[0].to) &&
                                                                (!date_production[0].from ||
                                                                    el.plan_of_production >=
                                                                    date_production[0].from) &&
                                                                (!date_production[0].to ||
                                                                    el.plan_of_production <=
                                                                    date_production[0].to)
                                                        )
                                                        .map((e) => {
                                                            const orderXncProblem = ordersXncStatusGroup?.find(item => Number(item._id) === Number(e.id) && ['error'].includes(item?.xnc?.status))

                                                            return (
                                                                <React.Fragment key={`tr-${e.id}`}>
                                                                    <tr key={`top_${e.id}`}>
                                                                        <RowTextId getT={props.getT} e={e}
                                                                                   InputCheck={<InputChecked name={e.id}
                                                                                                             checked={transfer_group_order.ids.includes(e.id)}
                                                                                                             onChange={(event) => {
                                                                                                                 onChangeTransferOrder(e.id)
                                                                                                             }}
                                                                                   />}/>

                                                                        <td data-label={props.getT("Название")}>
                                                                            <div title={e.name}
                                                                                 className={'text_line'}>   {e.name}</div>
                                                                        </td>
                                                                        <td data-label={props.getT("Точка производства")}>
                                                                            {e.adress.production}
                                                                        </td>
                                                                        <td data-label={props.getT("Дата подтверждения")}
                                                                            title={transformTimeAndDate(e.confirmed_date)?.time}>
                                                                            {transformTimeAndDate(e.confirmed_date)?.date}
                                                                        </td>
                                                                        <RowDateOfManufacture getT={props.getT} e={e}/>
                                                                        <ClientItem getT={props.getT} order={e}
                                                                                    clients={props.clients}>

                                                                        </ClientItem>
                                                                        <td data-label={props.getT("Действия")}>
                                                                            <div className="d-flex">
                                                                                <OrderXncAction getT={props.getT}
                                                                                                orderXncProblem={orderXncProblem}/>
                                                                                <OrderItemDeliverySlot delivery={getOrdersDeliveriesList?.[e.id]} orderId={e.id}/>

                                                                                <div
                                                                                    className="actions-container flex-full d-flex flex-wrap align-middle">
                                                                                    {canEdit ? (
                                                                                        props.getT("Недостаточно прав")
                                                                                    ) : (
                                                                                        <div
                                                                                            title={props.getT("Вернуть в выполненные")}
                                                                                            className={
                                                                                                "production-task-action text-danger d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                gaEvents.customEvent('klik_form', {
                                                                                                    order_change_status: 'change status'
                                                                                                })
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 5,
                                                                                                    from_status: e.status_code,
                                                                                                    to_status: 5,
                                                                                                })
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-left"></i>{" "}

                                                                                        </div>
                                                                                    )}

                                                                                    {e.id &&
                                                                                    e.programm_make &&
                                                                                    e.programm_make.length > 0 ? (
                                                                                        <div
                                                                                            title={props.getT("Генерация програм")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                gaEvents.customEvent('klik_form', {
                                                                                                    btn_programm_make: 'Генерация програм'
                                                                                                })
                                                                                                props.toggleViewProgramm(
                                                                                                    true,
                                                                                                    e.programm_make
                                                                                                )
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-fan"></i>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {canEdit ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <div
                                                                                            title={props.getT("отгружен")}
                                                                                            className={
                                                                                                "production-task-action text-success d-flex align-center mr-1"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                gaEvents.customEvent('klik_form', {
                                                                                                    order_change_status: 'change status'
                                                                                                })
                                                                                                props.updateOrder(e.id, {
                                                                                                    status: 7,
                                                                                                    from_status: e.status_code,
                                                                                                })
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-arrow-alt-circle-right"></i>{" "}
                                                                                        </div>
                                                                                    )}

                                                                                    <div title={props.getT("Просмотр")}
                                                                                         className={
                                                                                             "production-task-action text-danger d-flex align-center mr-1"
                                                                                         }
                                                                                    >
                                                                                        <Link
                                                                                            to={"/orders/detail/" + e.id}
                                                                                            target={"_blank"}
                                                                                        >
                                              <span
                                                  className={
                                                      "d-flex align-center mr-1 production_task_item_open"
                                                  }
                                                  onClick={() => {
                                                      gaEvents.customEvent('klik_form', {
                                                          click_link: 'open order detail',
                                                      })
                                                  }}
                                              >
                                                <i className="far fa-eye"> </i>{" "}
                                              </span>
                                                                                        </Link>
                                                                                    </div>
                                                                                    {e.hasOwnProperty('payment_status') &&
                                                                                        <span className={
                                                                                            "d-flex align-center mr-1 production_task_item_open"
                                                                                        }>
                                                                                        <strong className="">$</strong>
                                                                                        <small>{e.payment_status?.text}</small>
                                                                                    </span>}
                                                                                </div>

                                                                                <ButtonManualMode
                                                                                    isManualMode={isManualMode}
                                                                                    onToggleHandler={() => onToggleIdOrders(e.id)}
                                                                                    isOpen={ids_orders.includes(Number(e.id))}/>
                                                                            </div>


                                                                        </td>
                                                                    </tr>
                                                                    {(ids_orders.includes(Number(e.id)) || !isManualMode) &&
                                                                        <tr key={`bottom_${e.id}`}>
                                                                            <td
                                                                                style={{backgroundColor: "white"}}
                                                                                colSpan="7"
                                                                            >
                                                                                {props.settings[2]?.check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Материалы")}:</h6>
                                                                                            {e.mat.length > 0 &&
                                                                                                e.mat.map((material) => {

                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            {Number(material.sheet) > 0 &&
                                                                                                                <span>({material.sheet}листов)</span>}
                                                                                                            ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>{props.getT("Услуги")}:</h6>
                                                                                            {e.serv &&
                                                                                                e.serv.map((material) => {
                                                                                                    return (
                                                                                                        <h6
                                                                                                            style={{
                                                                                                                marginLeft: "10px"
                                                                                                            }}
                                                                                                        >
                                                                                                            {material.name} -{" "}
                                                                                                            {material.count.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            ,
                                                                                                        </h6>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[0].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Сырье")}: </h6>
                                                                                            {e.timeline &&
                                                                                                e.timeline.map((el) => {
                                                                                                    if (el.timeline?.length > 0) {
                                                                                                        let arr =
                                                                                                            el.timeline.filter(
                                                                                                                (e) =>
                                                                                                                    e.operation ===
                                                                                                                    "accept"
                                                                                                            );

                                                                                                        let sum = arr.reduce(
                                                                                                            function (
                                                                                                                accumulator,
                                                                                                                currentValue
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    accumulator +
                                                                                                                    Number(
                                                                                                                        currentValue.count
                                                                                                                    )
                                                                                                                );
                                                                                                            },
                                                                                                            0
                                                                                                        );
                                                                                                        if (
                                                                                                            sum === Number(el.count)
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <h6
                                                                                                                    style={{
                                                                                                                        marginRight: "10px",
                                                                                                                        marginLeft: "10px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}{" "}
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            marginLeft:
                                                                                                                                "10px",
                                                                                                                            marginBottom:
                                                                                                                                "10px"
                                                                                                                        }}
                                                                                                                    >
                                                            -{props.getT("Сырье выдано")},{" "}
                                                          </span>
                                                                                                                </h6>
                                                                                                            );
                                                                                                        }
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <h6
                                                                                                                style={{
                                                                                                                    marginRight: "10px",
                                                                                                                    marginLeft: "10px"
                                                                                                                }}
                                                                                                            >
                                                                                                                {el.info_good && el.hasOwnProperty('info_good') && el.info_good.hasOwnProperty(props.lang) && el.info_good[lang]}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        marginLeft: "10px",
                                                                                                                        marginBottom: "10px"
                                                                                                                    }}
                                                                                                                >
                                                          -{props.getT("Сырье не выданно")},
                                                        </span>
                                                                                                            </h6>
                                                                                                        );
                                                                                                    }
                                                                                                })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[1].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6> {props.getT("Место Хранения")}: </h6>
                                                                                            {e.pallete.map((pallet) => {
                                                                                                return (
                                                                                                    <h6
                                                                                                        style={{
                                                                                                            marginLeft: "10px"
                                                                                                        }}
                                                                                                    >
                                                                                                        {pallet.name} -{" "}
                                                                                                        {pallet.pallete}
                                                                                                    </h6>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                                {props.settings[3].check && (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "flex-start",
                                                                                                flexWrap: "wrap"
                                                                                            }}
                                                                                        >
                                                                                            <h6>
                                                                                                {props.getT("Производственное задание ")} №:
                                                                                            </h6>
                                                                                            {e.parent > 1 ? (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {e.parent}
                                                                                                </h6>
                                                                                            ) : (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        marginLeft: "10px"
                                                                                                    }}
                                                                                                >
                                                                                                    {props.getT("Задание не" +
                                                                                                        " включено ")}
                                                                                                    <br/>
                                                                                                    {props.getT(" в производственное задание")}
                                                                                                </h6>
                                                                                            )}
                                                                                        </div>
                                                                                        <hr/>
                                                                                    </>
                                                                                )}
                                                                            </td>


                                                                        </tr>}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    <div className="card-footer clearfix">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {activeOrder && (
                    <ServicesModal
                        open
                        services={activeOrder}
                        onChange={(services) => setActiveOrder(services)}
                        onSubmit={async () => {
                            await Promise.all(
                                activeOrder?.map((service) =>
                                    props.updateProductionStatus(service.id, service.status)
                                )
                            ).then(response => {
                                const UpdateOrderIn = props.ordersIn?.map(o => {
                                    if (Number(o.id) == Number(activeOrderInId)) {
                                        o.services_status = [
                                            ...activeOrder
                                        ]

                                    }
                                    return o;
                                });
                                let item_order_checked_status = UpdateOrderIn?.find(item => Number(item.id) === Number(activeOrderInId))?.services_status?.every(item => Number(item.status))
                                dispatch(orders_in(UpdateOrderIn));
                                if (item_order_checked_status) dispatch(orderOnUpdateServicesAndUpdateStatus(activeOrderInId, 4, 5))
                            })
                            setActiveOrder(null);
                        }}
                        onClose={() => setActiveOrder(null)}
                        getT={props.getT}
                    />
                )}
            </section>
            <SetProblemContainer/>
            <ProgrammMakeContainer/>
        </div>
    );
};

// component ТЕХ ID

const RowTextId = ({getT, e, InputCheck = null}) => {
    const dispatch = useDispatch();
    const changeProductionStageOrder = (order_id, stage, callback) =>
        dispatch(updateOrderProductionStageThunk(order_id, stage, callback));
    const [openChangeOrderStageProduction, changeOrderStageProduction] =
        useState(null);
    let hasStageNumber = e.production_stage_number ? true : false;
    const [isStageNumber, setIsStageNumber] = useState(hasStageNumber);
    const [value, setValue] = useState({
        current: e.production_stage_number ? e.production_stage_number : 0,
        prev: e.production_stage_number ? e.production_stage_number : 0
    });

    return (
        <td data-label={getT("ТЕХ ID")}>
            <div className="d-flex align-items-center justify-content-end justify-content-md-center">
                {InputCheck}
                <span className="p-1"></span>
                <span
                    className={
                        "track_status_order_production_task"
                    }
                >
                                        {e.id &&
                                        (e.adress_delivery === "" ||
                                            !e.adress_delivery) ? (
                                            " "
                                        ) : (
                                            <i className="fas fa-truck-moving"></i>
                                        )}
                                      </span>
                {Number(e.urgent) === 1 ? (
                    <span> &#128293; </span>
                ) : (
                    ""
                )}

                {Number(
                    openChangeOrderStageProduction
                ) === Number(e.id) ? (
                    <>
                        <div
                            className={
                                "change_order_stage_production_number_container"
                            }
                        >
                            <div className="change_order_stage_production_number_container_close">
                                              <span style={{
                                                  cursor: "pointer"
                                              }}
                                                    onClick={(event) => {
                                                        gaEvents.customEvent('klik_form', {
                                                            order_change_production_status: 'Очередь на производство'
                                                        })
                                                        if (value.current != value.prev) {
                                                            changeProductionStageOrder(
                                                                e.id,
                                                                value.current
                                                            );
                                                        }
                                                        changeOrderStageProduction(
                                                            null
                                                        );
                                                    }}
                                              >
                                                X
                                              </span>
                            </div>
                            <label>
                                {getT("Очередь на производство")}:
                            </label>
                            <input
                                type={"number"}
                                defaultValue={
                                    value.current
                                }
                                min={0}
                                max={999}
                                onChange={(e) => setValue({...value, prev: e.target.value})}
                                onBlur={(event) => {
                                    gaEvents.customEvent('klik_form', {
                                        order_change_production_status: 'Очередь на производство'
                                    })
                                    let currentValue = event.target.value;
                                    if (currentValue !== value.current) {
                                        changeProductionStageOrder(
                                            e.id,
                                            event.target.value,
                                            () => {
                                                setValue({
                                                    ...value,
                                                    current: currentValue
                                                });
                                                if (!isStageNumber) {
                                                    setIsStageNumber(true);
                                                }
                                            }
                                        );
                                    }

                                    changeOrderStageProduction(
                                        null
                                    );
                                }}
                            />
                        </div>
                        <span>
                                           {e.id} {isStageNumber &&
                            <>
                                <br/>{" "}
                                <small>
                                    {getT(`Очередь на производство`)}:
                                    {value.current}
                                </small>
                            </>}
                                          </span>
                    </>
                ) : (
                    <>
                                          <span
                                              onDoubleClick={() =>
                                                  changeOrderStageProduction(e.id)
                                              }
                                          >
                                           {e.id}
                                              {isStageNumber &&
                                                  <>
                                                      <br/>{" "}
                                                      <small>
                                                          {getT(`Очередь на производство`)}:
                                                          {value.current}
                                                      </small>
                                                  </>}

                                          </span>
                    </>
                )}
            </div>
        </td>
    )
}

// component ДАТА ПРОИЗВОДСТВ
const RowDateOfManufacture = ({getT, e}) => {
    const dispatch = useDispatch();
    const [openChangeDateProduction, changeDateProductionOpen] = useState(null);
    const changeProductionDateOrder = (order_id, date, callback) => {
        gaEvents.customEvent('klik_form', {
            order_change_date_manufacture: 'Дата производства'

        })
        dispatch(updateOrderProductionDateThunk(order_id, date, false, callback));
    }
    const [value, setValue] = useState(e.plan_of_production);
    return (
        <td data-label={getT("Производство")}>
            {Number(openChangeDateProduction) ===
            Number(e.id) ? (
                <>
                    <input
                        type={"date"}
                        defaultValue={e.plan_of_production.substr(
                            0,
                            10
                        )}
                        onBlur={(event) => {
                            let [currentDate, time] = value.split(' ');
                            let choiceDate = new Date(event.target.value).valueOf();
                            let current = new Date(currentDate).valueOf();

                            if (!(choiceDate === current)) {
                                changeProductionDateOrder(
                                    e.id,
                                    event.target.value, () => {
                                        let data = event.target.value + " " + "09:00:00";
                                        setValue(data);
                                    }
                                );
                            }

                            changeDateProductionOpen(null);
                        }}
                    />
                </>
            ) : (
                <>
                                          <span
                                              onDoubleClick={() =>
                                                  changeDateProductionOpen(e.id)
                                              }
                                              title={transformTimeAndDate(value)?.time}>
                                                                                {transformTimeAndDate(value)?.date}
                                          </span>
                </>
            )}
        </td>
    )
}

const ButtonManualMode = ({onToggleHandler, isOpen = false, isManualMode = true}) => {
    if (!isManualMode) return null
    const style = {margin: '5px 0px 0px 10px',}
    return <button onClick={onToggleHandler} className={'btn btn-sm btn-outline-primary'} style={style}><i
        className={`right fas fa-angle-${isOpen ? 'up' : 'down'}`}></i></button>
}

const ClientItem = ({getT, order, clients, children = null}) => {
    const [clienName, setClientName] = useState(order.client.name);
    const [isVisible, setIsVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [listPerson, setListPerson] = useState(false);
    const [choiseItem, setShoiseItem] = useState(false);
    const dispatch = useDispatch();

    const onSearchClients = (event) => {
        let value = event.target.value;
        const filtered = clients.filter((client) => {
            const phone = client.phone != null ? client.phone.replace(/[^\d]+/g, "") : null;
            if (value.length > 2) {
                return (
                    client.name.toLowerCase().includes(value.toLowerCase()) ||
                    client.phone.replace(/[^\d]+/g, "").includes(value)
                );
            }
        });
        gaEvents.customEvent('search', {
            search_term: value
        })
        setListPerson(filtered);
    }
    const changePeson = (clientId, orderId, name) => {
        gaEvents.customEvent('klik_form', {
            order_change_client: 'client'

        })
        dispatch(changeContragentDataThunc({"order_id": orderId, "order_change_client": clientId,}, () => {
            setClientName(name);
        }))
        setShoiseItem(prevState => {
            return prevState != clientId ? clientId : false
        });
    }
    return (
        <>
            <td data-label={getT("Клиент")} style={{paddingRight: '0.75rem'}}>
                <div className="d-flex align-items-start justify-content-center ">
                    <div className="flex-fill d-flex flex-nowrap column-client">
                        <Link target="_blank" to={"/clients/detail/" + order.client.id} title={clienName}>
                            <span className="text_line"> {clienName}</span>
                        </Link>
                        <div className="ml-1 change-personal">
                            <span onClick={() => setIsVisible(!isVisible)}><i className="fas fa-edit"></i></span>
                            <div className={(isVisible) ? "client-search show" : "client-search"}
                                 style={{right: "0px"}}>
                                <span className="close" onClick={() => setIsVisible(false)}>x</span>
                                <input type="text" defaultValue={searchValue} onChange={onSearchClients}/>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>{getT("id")}</th>
                                        <th>{getT("Имя")}</th>
                                        <th>{getT("Телефон")}</th>
                                    </tr>
                                    </thead>


                                    {listPerson && <tbody style={{maxHeight: "250px", overflowY: "auto"}}>
                                    {listPerson.map(item => {
                                        return (
                                            <tr onClick={() => changePeson(item.id, order.id, item.name)}
                                                className={(choiseItem == item.id) ? 'active' : ''}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.phone}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>}
                                </table>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>


            </td>
        </>
    )
}
export default withLang(ProductionTask);
// export default withCookies(withLang(ProductionTask));
