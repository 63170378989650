export const utilsTypeof = {
    checkObject: (value) => {
        return (typeof value === "object") && !Array.isArray(value) && (value !== null)
    },
    isValidNumber: (value) => {
        return value !== null && value !== undefined && value !== '' && !isNaN(Number(value));
    },
    isObject: (value) => {
        return (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
        );
    },
    isArray: (value) => {
        return Array.isArray(value);
    }
}
