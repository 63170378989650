import {setOrderDeliveriesAction} from "../../actions/orderDeliveriesAction";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {CommonOrderAPI} from "../../../api/Order/CommonOrderAPI";
import {dispatcherErrorThunk} from "../common_thunk";

/*
*  метод для получения списка доставок
*   arrayOrdersIds - массив id закозов
* **/
export const caseOrdersDeliveriesListThunk = (arrayOrdersIds) => async dispatch => {
    if (!Array.isArray(arrayOrdersIds) || !arrayOrdersIds?.length) {
        dispatch(setOrderDeliveriesAction(null));
        return;
    }
    try {
        dispatch(toggleLoaderStatusAC(true));

        const result = await CommonOrderAPI.getOrdersDeliveriesList(arrayOrdersIds);

        if (result?.error) {
            dispatch(dispatcherErrorThunk({error: result.error}))
            dispatch(setOrderDeliveriesAction(null));
            return;
        }
        const data = result?.data ?? {}
        const deliveryExistingOrder = Object.keys(data).reduce((acc, orderId)=>{
            const order = data[orderId];
            if(order.hasOwnProperty('error')){
                return acc;
            }
            return {...acc, [orderId]: data[orderId]};
        }, {});


        dispatch(setOrderDeliveriesAction(deliveryExistingOrder));
        dispatch(toggleLoaderStatusAC(false));

    } catch (err) {
        console.error(err);
        dispatch(dispatcherErrorThunk(err))
    }
}
