import {utilsTypeof} from "../../utils/utilsTypeof";


export default class AddressDeliveryMapper {
    static isDropdownOrSelectField(fieldType) {
        return fieldType === "DropdownField" || fieldType === "LookupField";
    }

    static isTextField(fieldType) {
        return fieldType === "TextField";
    }

    static map(delivery) {
        if (!utilsTypeof.isObject(delivery)) {
            return null
        }
        const form = Array.isArray(delivery?.form) ? delivery.form : [];

        const view = form.reduce((acc, field) => {
            if (!field?.value) {
                return acc
            }

            if (AddressDeliveryMapper.isDropdownOrSelectField(field.type)) {
                const value = field.options?.option?.[field.value] ?? '';
                acc.push({label: field.label, value: value})
            } else if (AddressDeliveryMapper.isTextField(field.type)) {
                acc.push({label: field.label, value: field.value})
            }
            return acc

        }, []);


        return {
            name: delivery?.name ?? '',
            plugin: delivery?.plugin ?? '',
            form: view
        }
    }
}