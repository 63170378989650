import React, {useState} from 'react';
import Modal from "../Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getOrderXncProblemModal} from "../../store/selectors/orders-xnc-status-selector";
import {orderXncProblemModalAc} from "../../store/actions/OrdersXncStatusActions";
import ReactJson from 'react-json-view'
import {utilsTypeof} from "../../utils/utilsTypeof";
import OperationsInOrderList from "./ui/OperationsInOrderList";
import {withLang} from "../../hoc/withLang";


const OrderXncProblemModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(getOrderXncProblemModal);
    const [isErrorTab, changeErrorTab] = useState(true);

    if (!modal.isOpen) return null;
    const onClose = () => {
        dispatch(orderXncProblemModalAc({isOpen: false, order: null}));
    }
    return (
        <Modal title={getT("Проблемы в производстве")} open={modal.isOpen} onClose={onClose}>
            <OperationsInOrderList getT={getT} operations={modal.order?.[0]?.operations_in_order}/>

            <div className="">
                <ul className="nav nav-tabs justify-content-center border-bottom-0 mb-2" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${isErrorTab ? ' active text-primary ' : ' '}`}
                                onClick={() => changeErrorTab(true)}>
                            {getT("Ошибки")}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${isErrorTab ? ' ' : ' active text-primary '}`}
                                onClick={() => changeErrorTab(false)}>
                            {getT("Список станков")}
                        </button>
                    </li>
                </ul>

                {isErrorTab && <div className="nav nav-tabs justify-content-center border-bottom-0">
                    {utilsTypeof.isObject(modal.order?.[0]?.error)
                        ? <ReactJson src={modal.order?.[0]?.error} collapsed={true}/>
                        : null}
                </div>}
                {!isErrorTab && <div className="nav nav-tabs justify-content-center border-bottom-0">
                    {utilsTypeof.isObject(modal.order?.[0]?.error)
                        ? <ReactJson src={modal.order?.[0]?.equipment_problem} collapsed={true}/>
                        : null}
                </div>}
            </div>
        </Modal>
    );
};

export default withLang(OrderXncProblemModal)