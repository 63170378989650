import getT from "../getT";

let sideFromTranslate = {
    t: getT("Сверху"),
    b: getT("Снизу"),
    l: getT("Слева"),
    r: getT("Справа"),
}
let sideTranslate = {
    f: getT("Лицевая"),
    bb: getT("Обратная"),
    t: getT("Верхний торец"),
    b: getT("Нижний торец"),
    l: getT("Левый торец"),
    r: getT("Правый торец"),
}

export default function (pazItemBySide) {
    let info = pazItemBySide?.info ?? {};
    let parts = pazItemBySide?.parts ?? [];

    let partRender = parts?.map(part => {
        return `
            #${part.id} ${part.name}  (${part?.x}x${part?.y}x${part.z})
        `
    }).join('<hr/>')
    const pictureRender =()=>{
        if(!info?.picture) return ``
        return `<img  width="130px" src="data:image/svg+xml;base64,${info?.picture}"/>`
    }
    return `
 
            <tr>
                    <th>
                        ${sideFromTranslate?.[info?.side_from]}  ${info?.d1} ${getT("мм")}, 
                        <span class="text-lowercase">${getT('Сторона')}</span>
                        <span class="text-lowercase">${sideTranslate?.[info?.side]}</span>
                    </th>
                      <td class="text-center">
                        ${pictureRender()}
                      </td>
                    <td>${partRender}</td>
             </tr>
    `
}