import React from 'react';
import {utilsTypeof} from "../../../utils/utilsTypeof";
import OperationsInOrderList from "../../OrderXncStatus/ui/OperationsInOrderList";
import IconWarning from "../../../Icons/IconWarning";
import {orderXncProblemModalThunk} from "../../../store/thunks/orders/order-xnc-status-thunk";
import {useDispatch} from "react-redux";

const OrderXncAction = ({orderXncProblem, getT}) => {
   const dispatch = useDispatch();

    if(!utilsTypeof.isObject(orderXncProblem)) {
        return null;
    }
    const openOrderXncProblemModal = () => {
        console.log('order', orderXncProblem)
        dispatch(orderXncProblemModalThunk(orderXncProblem._id));
    }
    return (
        <div className={'cursor-pointer tooltip-parent'}>

            <IconWarning onClick={openOrderXncProblemModal}/>
            <OperationsInOrderList operations={orderXncProblem?.xnc?.operations_in_order}
                                   getT={getT} className={'tooltip-bl order-count-detail'}/>
        </div>
    );
};

export default OrderXncAction;