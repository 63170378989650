import React from 'react';
import {utilsTypeof} from "../../utils/utilsTypeof";
import {useDispatch} from "react-redux";
import {setOrderDeliveryViewInforModalAction} from "../../store/actions/orderDeliveriesAction";
/*
*  компонента для item order
*  так же открывает модальное окно для просмотра полной инфы о доставки
* **/
const OrderItemDeliverySlot = ({delivery = null, orderId}) => {
    const dispatch = useDispatch();
    if (!utilsTypeof.isObject(delivery)) {
        return null;
    }
    const openDeliveryViewInfoModal = () => {
        dispatch(setOrderDeliveryViewInforModalAction({isOpen: true, delivery: {...delivery, orderId: orderId}}));
    }

    return (
        <i className="fa fa-truck cursor-pointer text-orange ml-1 mr-1" onClick={openDeliveryViewInfoModal}></i>
    );
};

export default OrderItemDeliverySlot;