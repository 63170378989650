import React from 'react';

/* Вывод обработки торцов примененные в проекте

* **/
const ProcessingEdgeCalculateOrderDetail = ({calculate, getT}) => {
    if (!calculate?.srez_edge?.entry?.length && !calculate?.faska_edge?.entry?.length && !calculate?.handlers_edge?.entry?.length && !calculate?.round_edge?.entry?.length) return null


    return (
        <>
            <tr className={"bg-lightgray"}>
                <td colSpan="7" className={"text-center"}>
                    <b>{getT('Обработка торцов')}</b>
                </td>
            </tr>
            {!!(Array.isArray(calculate?.handlers_edge?.entry) && calculate?.handlers_edge?.entry?.length) && <>
                <tr className={"bg-lightgray"}>
                    <td colSpan="7" className={"text-left"}>
                        <b>{getT('Ручка')}</b>
                    </td>
                </tr>
                {
                    calculate?.handlers_edge?.entry?.map((item) => {
                        return item?.hand?.map((e) => {
                            return MapperProcessingTyViewCalculate(e, "шт", getT)
                        })

                    })}
            </>}
            {!!(Array.isArray(calculate?.faska_edge?.entry) && calculate?.faska_edge?.entry?.length) && <>
                <tr className={"bg-lightgray"}>
                    <td colSpan="7" className={"text-left"}>
                        <b>{getT('Фаска')}</b>
                    </td>
                </tr>
                {calculate?.faska_edge?.entry?.map((item) => {
                    return item?.faska?.map((e) => {
                        return MapperProcessingTyViewCalculate(e, "м.п.", getT)
                    })

                })}
            </>}


            {!!(Array.isArray(calculate?.round_edge?.entry) && calculate?.round_edge?.entry?.length) &&
                <>
                    <tr className={"bg-lightgray"}>
                        <td colSpan="7" className={"text-left"}>
                            <b>{getT('Скругление')}</b>
                        </td>
                    </tr>
                    {calculate?.round_edge?.entry?.map((item, index) => {
                        return item?.round?.map((e, index) => {
                            return MapperProcessingTyViewCalculate(e, "м.п.", getT)
                        })

                    })}

                </>}
            {!!(Array.isArray(calculate?.srez_edge?.entry) && calculate?.srez_edge?.entry?.length) && <>
                <tr className={"bg-lightgray"}>
                    <td colSpan="7" className={"text-left"}>
                        <b>{getT('Срез')}</b>
                    </td>
                </tr>
                {calculate?.srez_edge?.entry?.map((item, index) => {

                    return item?.srez?.map((e, index) => {
                        return MapperProcessingTyViewCalculate(e, "м.п.", getT)
                    })

                })}

            </>
            }
        </>)
};


const MapperProcessingTyViewCalculate = (item, unit, getT) => {
    return <tr>
        <td colSpan={3}
            data-label={getT("Название")}>{item.name}</td>
        <td data-label={getT("Количество")}>{item.count} {getT(unit)}</td>
        <td data-label={getT("Цена")}>{item?.price} {item?.currency_name}</td>
        <td data-label={getT("Сумма")}>{item.cost} {item?.currency_name}</td>
        <td></td>
    </tr>

}
export default ProcessingEdgeCalculateOrderDetail;