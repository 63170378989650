export const ORDERS_XNC_STATUS_SET_GROUP = "ORDERS_XNC_STATUS_SET_GROUP";
export const ORDER_XNC_PROBLEM__MODAL = "ORDER_XNC_PROBLEM__MODAL";

export const ordersXncStatusSetAc = (ordersXncGroup) => {
    return {
        type: ORDERS_XNC_STATUS_SET_GROUP,
        payload: ordersXncGroup
    }
}
export const orderXncProblemModalAc = ({isOpen, order}) => {
    return {
        type: ORDER_XNC_PROBLEM__MODAL,
        payload: {isOpen, order}
    }
}