import React, {useEffect, useCallback, useRef} from "react";
import "./Modal.css";

const Modal = ({open, onClose, children, title = null, size_modal_class = "modal-lg"}) => {
    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && open) {
                onClose();
            }
        },
        [onClose, open]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);
        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);


    if (!open) {

        return null;
    }

    return (
        <div className="modal-open">
            <div className="modal-bg" onClick={closeModal}>
                <div className={"modal fade show"} id="modal-lg">
                    <div className={`modal-dialog ${size_modal_class}`}>
                        <div className="modal-content">
                            <div className="modal-header w-100 mb-2">
                                <h4 className="modal-title">
                                    {title}

                                </h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                            onClose();
                                            document.body.classList.remove("modal-open")
                                        }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body w-100">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Modal;
