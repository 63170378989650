import React from 'react';
import {utilsTypeof} from "../../../utils/utilsTypeof";

const operationsObjVal = {
    paz: {title: "Сверление"},
    bore: {title: "Пазование"},
    frez: {title: "Фрезерование"},
    cut_to: {title: "Урезка"},
    hem: {title: "Сшивка"},
    trough: {title: "Выемка"},
}

const OperationsInOrderList = ({operations, getT, className}) => {
    // if((typeof operations !== "object" || operations === null || !operations)) return null;
    if (!utilsTypeof.isObject(operations)) {
        return null;
    }
    return (
        <ul className={`operations-in-order-list ${className} `}>
            {Object.keys(operations).map((key, index) => {
                const title = operationsObjVal[key].title;
                const value = operations[key];
                return (
                    <li key={key}>{getT(title)}: {value}</li>
                )
            })}
        </ul>
    );
};

export default OperationsInOrderList;