import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {CommonOrderAPI} from "../../../api/Order/CommonOrderAPI";
import {ordersXncStatusSetAc, orderXncProblemModalAc} from "../../actions/OrdersXncStatusActions";

export const getOrdersXncStatusByGroupThunk = (arrayOrdersIds) => async dispatch => {
    if (!Array.isArray(arrayOrdersIds) || !arrayOrdersIds?.length) {
        dispatch(ordersXncStatusSetAc(null));
        return;
    }
    try {
        dispatch(toggleLoaderStatusAC(true));

        const result = await CommonOrderAPI.getOrdersXncStatusByGroup(arrayOrdersIds);

        if (result?.error) {
            dispatch(dispatcherErrorThunk({error: result.error}))
            dispatch(ordersXncStatusSetAc(null));
            return;
        }
        dispatch(ordersXncStatusSetAc(result.data));
        dispatch(toggleLoaderStatusAC(false));

    } catch (err) {
        console.error(err);
        dispatch(dispatcherErrorThunk(err))
    }
}

export const orderXncProblemModalThunk = (orderId) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const result = await CommonOrderAPI.getOrdersXncStatusById(orderId);

        if (result?.error) {
            dispatch(dispatcherErrorThunk({error: result.error}))
            dispatch(orderXncProblemModalAc({isOpen: false, order: null}));
            return;
        }
        dispatch(orderXncProblemModalAc({isOpen: true, order: result.data}));

        dispatch(toggleLoaderStatusAC(false));
    } catch (err) {
        console.error(err);
        dispatch(dispatcherErrorThunk(err))
    }
}