import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";


/**
 * Базовые справочники
 * @method get  - список пользователей
 */
const order_production_update = "order_production_update"
const order_generate_xnc_to_queue = "order_generate_xnc_to_queue"
const get_orders_xnc_status_by_id = "get_orders_xnc_status_by_id"
const get_orders_xnc_status_by_group = "get_orders_xnc_status_by_group"
const get_orders_deliveries_list = "get_orders_deliveries_list"

export const CommonOrderAPI = {
    update_setting_in_order(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: order_production_update
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    orderGenerateXncToQueue (form_data){
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: order_generate_xnc_to_queue
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getOrdersXncStatusById (id){
        return instances.post(URI_API_EXTTUNELL, {id: id}, {
            headers: {
                action: get_orders_xnc_status_by_id
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getOrdersXncStatusByGroup (arrIds){
        return instances.post(URI_API_EXTTUNELL, arrIds, {
            headers: {
                action: get_orders_xnc_status_by_group
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getOrdersDeliveriesList(ordersIds){
        return instances.post(URI_API_EXTTUNELL, {ids: ordersIds}, {
            headers: {
                action: get_orders_deliveries_list
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }

}